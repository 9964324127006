import React, { useState } from 'react';
import AboutUser from './AboutUser';
import MenuForDesktop from './MenuForDesktop';
import MenuForMobile from './MenuForMobile';
import SparksSubMenu from './SparksSubMenu/SparksSubMenu';
import SparksSubMenuDesktop from './SparksSubMenu/SparksSubMenuDesktop';
import ProfileBackground from './ProfileBackground';
import styles from './UserHeader.module.css';

const UserHeader = ({ user }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(1);

  return (
    <div className={styles['main-container']}>
      <ProfileBackground user={user} />
      <div className={styles['width-container']}>
        <AboutUser user={user} />
        <MenuForDesktop
          selectedMenuKey={selectedMenuKey}
          setSelectedMenuKey={setSelectedMenuKey}
        />
        <MenuForMobile
          selectedMenuKey={selectedMenuKey}
          setSelectedMenuKey={setSelectedMenuKey}
        />
        {selectedMenuKey === 1 && (
          <React.Fragment>
            <SparksSubMenu user={user} />
            <SparksSubMenuDesktop user={user} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default UserHeader;
