import React, { useEffect, useState } from 'react';
import UserProfileHeader from './components/UserProfileHeader';
import GravesUserJoined from './components/GravesUserJoined';
import UserConnections from './components/UserConnections';
import UserWall from './components/UserWall';
import { fetch_request } from '../../service/request';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GraveInviteModal from '../../components/GraveInviteModal/GraveInviteModal';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import SubscriptionInviteModal from '../../components/SubscriptionInviteModal/SubscriptionInviteModal';
import isEmpty from 'lodash.isempty';
import NotFoundPage from '../404/404';
import spinner from '../../service/Loading_animation.gif';
import './UserProfile.css';
import PrimaryButton from '../../components/PrimaryButton';
import { FormattedMessage } from 'react-intl';

const UserProfile = () => {
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const loggedInUser = useSelector((state) => state.user.user);
  const [currentUser, setCurrentUser] = useState({});
  const [graveInvitation, setGraveInvitation] = useState();
  const navigate = useNavigate();
  let { slug } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentLoggedInUser = localStorage.getItem('user-slug');

  const handelCloseModal = () => {
    setOpenModal(false);
  };

  const handelCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false);
  };

  const handelAction = (value) => {
    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/grave_invitations/${graveInvitation.id}/${value}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          showUserNotification('Invitasjonen er avslå', 'warning');
        } else {
          navigate(`/deceased/${graveInvitation.grave.slug}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenModal(false);
  };

  useEffect(() => {
    if (slug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      fetch_request(`/api/users/${slug}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setCurrentUser(data.user);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [slug]);

  useEffect(() => {
    if (!isEmpty(loggedInUser) && currentLoggedInUser === slug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      fetch_request(
        `/api/find_grave_invitations/${loggedInUser.email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setOpenModal(false);
          } else {
            setGraveInvitation(data);
            setOpenModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      loggedInUser.subscription_invitation &&
      slug === currentLoggedInUser
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [loggedInUser]);

  if (isEmpty(loggedInUser) && !loading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={spinner} atl='loading spinner' />
      </div>
    );
  }

  const navigateToNewPage = () => {
    navigate(`/user/${currentUser.slug}`);
  };

  return (
    <>
      <div className='member-page'>
        <div style={{ margin: '12px' }}>
          <PrimaryButton
            onClick={navigateToNewPage}
            children={<FormattedMessage id='Go to new Page' />}
          />
        </div>
        <div className='member-header_placeholder'>
          <div className='member-header_outer_container'>
            <UserProfileHeader user={currentUser} />
          </div>
        </div>
        <div className='member-content_outer_container'>
          <div className='member-content_big_left'>
            <GravesUserJoined user={currentUser} />
            <UserConnections user={currentUser} />
          </div>
          <div className='member-content_big_right'>
            {currentUser?.is_related ? (
              <UserWall key={Math.random(10)} user={currentUser} />
            ) : (
              <p
                className={
                  currentUser.full_name
                    ? 'unrelated-user'
                    : 'animated-background'
                }
              >
                {currentUser.full_name
                  ? `Knytt ${currentUser.full_name} til deg ved å sette relasjon`
                  : ''}
              </p>
            )}
          </div>
        </div>
        {openModal && graveInvitation && (
          <GraveInviteModal
            onOpen={openModal}
            onClose={handelCloseModal}
            handelAction={handelAction}
            graveInvitation={graveInvitation}
            currentUser={loggedInUser}
          />
        )}
      </div>
      {openSubscriptionModal && (
        <SubscriptionInviteModal
          onOpen={openSubscriptionModal}
          onClose={handelCloseSubscriptionModal}
          currentUser={loggedInUser}
        />
      )}
    </>
  );
};

export default UserProfile;
