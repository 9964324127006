import React, { useState } from 'react';
import styles from './SecondModal.module.css';
import X from '../../../../img/X.svg';
import Pointer from '../../../../img/BackIcon.svg';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addPhotoForCropping } from '../../../../redux/actions';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#404d56',
    color: 'white',
    padding: '12px 42px',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'none',
  },
}));

const SecondModal = (props) => {
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const classes = useStyle();
  const [clickPosition, setClickPositon] = useState();
  const [age, setAge] = useState('');
  const [ages, setAges] = useState([]);
  const dispatch = useDispatch();

  const pointOnThePicture = (event) => {
    const rect = event.target.getBoundingClientRect();
    setClickPositon({
      left: (100 * event.nativeEvent.offsetX) / rect.width,
      top: (100 * event.nativeEvent.offsetY) / rect.height,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (age.trim()) {
      setAges((prev) => [
        ...prev,
        { position: clickPosition, age: age, id: Date.now().toString() },
      ]);
      setClickPositon(null);
      setAge('');
    }
  };

  const saveAllChanges = () => {
    if (photoForCropping.length > 0) {
      const selectedImage = photoForCropping.map((image) =>
        image.id === props.croppedImage.id ? { ...image, ages: ages } : image
      );
      if (!isEmpty(selectedImage)) dispatch(addPhotoForCropping(selectedImage));
      props.openFirst();
    }
  };

  return (
    <div className={styles['second-modal']}>
      <div className={styles['second-modal_content']}>
        <div className={styles['second-modal_content-header']}>
          <span>Sett alder ved å trykke på personen</span>
        </div>
        <div
          className={styles['second-modal_content-go-back']}
          onClick={props.openFirst}
        >
          <img loading='lazy' src={Pointer} alt='pinter' />
          <span>Tilbake</span>
        </div>
        <div className={styles['second-modal_content-cropped-image']}>
          <img
            loading='lazy'
            src={props.croppedImage.file_url}
            alt='cropped'
            onClick={pointOnThePicture}
          />
          {clickPosition && (
            <div
              className={styles['second-modal_content-cropped-image_form']}
              style={{
                borderRadius: '4px',
                position: 'absolute',
                top: `${parseFloat(clickPosition.top) + 2}%`,
                left: `${parseFloat(clickPosition.left) - 5}%`,
                width: '59px',
              }}
            >
              <form
                onSubmit={handleFormSubmit}
                className={
                  styles['second-modal_content-cropped-image_form-form']
                }
              >
                <input
                  type='number'
                  required
                  value={age}
                  onChange={(e) =>
                    e.target.value >= 0 && setAge(e.target.value)
                  }
                  autoFocus
                />
                <Button
                  className={
                    styles[
                      'second-modal_content-cropped-image_form-form_button-submit'
                    ]
                  }
                  type='submit'
                >
                  Sett
                </Button>
              </form>
            </div>
          )}
        </div>
        <div className={styles['second-modal_content-subheader']}>
          <span>Klikk på personen i bildet for å sette alder</span>
        </div>
        <div className={styles['second-modal_content-buttons']}>
          <Button
            variant='contained'
            className={classes.root}
            onClick={saveAllChanges}
          >
            Lagre
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecondModal;
