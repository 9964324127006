import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SparksSubMenuDesktop.module.css';
import Sparks from './Sparks/CreateSparks/Sparks';

const SparksSubMenuDesktop = ({ user }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(1);
  const menuItems = {
    1: {
      titleKey: 'sparks',
    },
    2: {
      titleKey: 'graves',
    },
    3: {
      titleKey: 'wisdom',
    },
  };

  return (
    <div className={styles['menu-section']}>
      <div className={styles['container']}>
        {Object.entries(menuItems).map(([key, item]) => {
          const newKey = Number(key);
          return (
            <div
              key={key}
              className={
                selectedMenuKey === newKey
                  ? `${styles['menu-item']} ${styles['active']}`
                  : styles['menu-item']
              }
              onClick={() => setSelectedMenuKey(newKey)}
            >
              <div className={styles['item']}>
                <FormattedMessage id={item.titleKey} />
              </div>
            </div>
          );
        })}
      </div>
      {selectedMenuKey === 1 && <Sparks user={user} />}
    </div>
  );
};

export default SparksSubMenuDesktop;
