import React from 'react';
import CreateSparks from './CreateSparks.jsx';
import SparksList from '../SparksList/SparksList.jsx';
import { useSparkCategoriesQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import { useSparksQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import Loader from '../../../../../../components/Loader/Loader.jsx';

const Sparks = ({ user }) => {
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: categoriesData,
  } = useSparkCategoriesQuery();

  const {
    loading: loadingSparks,
    error: errorSparks,
    data: sparksData,
  } = useSparksQuery({
    variables: {
      first: 10,
    },
  });

  if (loadingCategories || loadingSparks) {
    return <Loader />;
  }

  return (
    <div>
      <CreateSparks
        user={user}
        sparkCategories={categoriesData.sparkCategories}
      />
      <SparksList
        sparksList={sparksData.sparks.items}
        sparkCategories={categoriesData.sparkCategories}
      />
    </div>
  );
};

export default Sparks;
