import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601Date: { input: any; output: any; }
  ISO8601DateTime: { input: any; output: any; }
};

/** Autogenerated return type of CreateGrave. */
export type CreateGravePayload = {
  __typename?: 'CreateGravePayload';
  grave?: Maybe<Grave>;
};

/** Autogenerated return type of CreateMoon. */
export type CreateMoonPayload = {
  __typename?: 'CreateMoonPayload';
  errors: Array<Scalars['String']['output']>;
  moon?: Maybe<Moon>;
};

/** Autogenerated return type of CreateSpark. */
export type CreateSparkPayload = {
  __typename?: 'CreateSparkPayload';
  errors: Array<Scalars['String']['output']>;
  spark?: Maybe<Spark>;
};

/** Autogenerated return type of CreateSparkReaction. */
export type CreateSparkReactionPayload = {
  __typename?: 'CreateSparkReactionPayload';
  errors: Array<Scalars['String']['output']>;
  sparkReaction?: Maybe<SparkReaction>;
};

export type DeathCertificate = {
  __typename?: 'DeathCertificate';
  certificate?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  graveId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated return type of DestroyMoon. */
export type DestroyMoonPayload = {
  __typename?: 'DestroyMoonPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroySpark. */
export type DestroySparkPayload = {
  __typename?: 'DestroySparkPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroySparkReaction. */
export type DestroySparkReactionPayload = {
  __typename?: 'DestroySparkReactionPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Grave = {
  __typename?: 'Grave';
  burial?: Maybe<Scalars['ISO8601Date']['output']>;
  category?: Maybe<GraveCategory>;
  countryId?: Maybe<Scalars['Int']['output']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  dateOfDeath?: Maybe<Scalars['ISO8601Date']['output']>;
  deathCertificate?: Maybe<DeathCertificate>;
  gender?: Maybe<GraveGender>;
  graveStatus?: Maybe<GraveStatus>;
  graveUsers?: Maybe<Array<GraveUser>>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  municipality?: Maybe<Scalars['String']['output']>;
  municipalityId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otherReasonOfDeath?: Maybe<Scalars['String']['output']>;
  reasonOfDeathId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum GraveCategory {
  /** physical */
  Physical = 'physical',
  /** virtual */
  Virtual = 'virtual'
}

export enum GraveGender {
  /** annen */
  Annen = 'annen',
  /** kvinne */
  Kvinne = 'kvinne',
  /** mann */
  Mann = 'mann'
}

export enum GraveStatus {
  /** accepted */
  Accepted = 'accepted',
  /** claimed */
  Claimed = 'claimed',
  /** pending_review */
  PendingReview = 'pending_review',
  /** rejected */
  Rejected = 'rejected'
}

export type GraveUser = {
  __typename?: 'GraveUser';
  admin?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deathCertificate?: Maybe<DeathCertificate>;
  graveId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  relationId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<GraveUserStatus>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export enum GraveUserStatus {
  /** accepted */
  Accepted = 'accepted',
  /** pending_review */
  PendingReview = 'pending_review',
  /** rejected */
  Rejected = 'rejected'
}

export type Moon = {
  __typename?: 'Moon';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  moonId: Scalars['ID']['output'];
  moonName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createGrave?: Maybe<CreateGravePayload>;
  /** Create a new moon */
  createMoon: CreateMoonPayload;
  /** Creates a new spark */
  createSpark: CreateSparkPayload;
  /** Creates a new sparkReaction */
  createSparkReaction: CreateSparkReactionPayload;
  /** Destroy a moon */
  destroyMoon: DestroyMoonPayload;
  /** Deletes an existing spark */
  destroySpark: DestroySparkPayload;
  /** Destroy a spark reaction */
  destroySparkReaction: DestroySparkReactionPayload;
  /** Updates an existing spark */
  updateSpark: UpdateSparkPayload;
  /** Updates an existing sparkReaction */
  updateSparkReaction: UpdateSparkReactionPayload;
};


export type MutationCreateGraveArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateMoonArgs = {
  moonId: Scalars['ID']['input'];
};


export type MutationCreateSparkArgs = {
  description: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSparkReactionArgs = {
  reactionType: Scalars['String']['input'];
  sparkId: Scalars['ID']['input'];
};


export type MutationDestroyMoonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroySparkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroySparkReactionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateSparkArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSparkReactionArgs = {
  id: Scalars['ID']['input'];
  reactionType: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  moon?: Maybe<Moon>;
  moons?: Maybe<Array<Moon>>;
  spark?: Maybe<Spark>;
  sparkCategories?: Maybe<Array<SparkCategory>>;
  sparkCategory?: Maybe<SparkCategory>;
  sparkReaction?: Maybe<SparkReaction>;
  sparkReactions?: Maybe<Array<SparkReaction>>;
  sparks: SparkPagination;
};


export type QueryMoonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMoonsArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySparkArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySparkCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySparkReactionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySparkReactionsArgs = {
  sparkId: Scalars['ID']['input'];
};


export type QuerySparksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type Spark = {
  __typename?: 'Spark';
  categoryName: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  sparkCategoryId: Scalars['ID']['output'];
  sparkReactionsCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userAvatar?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
  visibility?: Maybe<Scalars['String']['output']>;
};

export type SparkCategory = {
  __typename?: 'SparkCategory';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SparkPagination = {
  __typename?: 'SparkPagination';
  items: Array<Spark>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  previousCursor?: Maybe<Scalars['String']['output']>;
};

export type SparkReaction = {
  __typename?: 'SparkReaction';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  reactionType: Scalars['String']['output'];
  sparkId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

/** Autogenerated return type of UpdateSpark. */
export type UpdateSparkPayload = {
  __typename?: 'UpdateSparkPayload';
  errors: Array<Scalars['String']['output']>;
  spark?: Maybe<Spark>;
};

/** Autogenerated return type of UpdateSparkReaction. */
export type UpdateSparkReactionPayload = {
  __typename?: 'UpdateSparkReactionPayload';
  errors: Array<Scalars['String']['output']>;
  sparkReaction?: Maybe<SparkReaction>;
};

export type SparkCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SparkCategoriesQuery = { __typename?: 'Query', sparkCategories?: Array<{ __typename?: 'SparkCategory', id: string, name?: string | null }> | null };

export type CreateSparkMutationVariables = Exact<{
  description: Scalars['String']['input'];
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateSparkMutation = { __typename?: 'Mutation', createSpark: { __typename?: 'CreateSparkPayload', spark?: { __typename?: 'Spark', id: string, description?: string | null, visibility?: string | null, location?: string | null, sparkCategoryId: string, categoryName: string, sparkReactionsCount: number, userId: string, userName: string, userAvatar?: string | null, createdAt: any } | null } };

export type UpdateSparkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSparkMutation = { __typename?: 'Mutation', updateSpark: { __typename?: 'UpdateSparkPayload', spark?: { __typename?: 'Spark', id: string, description?: string | null, sparkCategoryId: string, categoryName: string, visibility?: string | null, location?: string | null, userId: string } | null } };

export type DestroySparkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DestroySparkMutation = { __typename?: 'Mutation', destroySpark: { __typename?: 'DestroySparkPayload', id?: string | null, errors: Array<string> } };

export type SparksQueryVariables = Exact<{
  first: Scalars['Int']['input'];
}>;


export type SparksQuery = { __typename?: 'Query', sparks: { __typename?: 'SparkPagination', nextCursor?: string | null, previousCursor?: string | null, items: Array<{ __typename?: 'Spark', id: string, description?: string | null, visibility?: string | null, location?: string | null, sparkCategoryId: string, categoryName: string, sparkReactionsCount: number, userId: string, userName: string, userAvatar?: string | null, createdAt: any }> } };


export const SparkCategoriesDocument = gql`
    query SparkCategories {
  sparkCategories {
    id
    name
  }
}
    `;

/**
 * __useSparkCategoriesQuery__
 *
 * To run a query within a React component, call `useSparkCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparkCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparkCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSparkCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
      }
export function useSparkCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
        }
export function useSparkCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
        }
export type SparkCategoriesQueryHookResult = ReturnType<typeof useSparkCategoriesQuery>;
export type SparkCategoriesLazyQueryHookResult = ReturnType<typeof useSparkCategoriesLazyQuery>;
export type SparkCategoriesSuspenseQueryHookResult = ReturnType<typeof useSparkCategoriesSuspenseQuery>;
export type SparkCategoriesQueryResult = Apollo.QueryResult<SparkCategoriesQuery, SparkCategoriesQueryVariables>;
export const CreateSparkDocument = gql`
    mutation CreateSpark($description: String!, $sparkCategoryId: ID!, $visibility: String, $location: String) {
  createSpark(
    description: $description
    sparkCategoryId: $sparkCategoryId
    visibility: $visibility
    location: $location
  ) {
    spark {
      id
      description
      visibility
      location
      sparkCategoryId
      categoryName
      sparkReactionsCount
      userId
      userName
      userAvatar
      createdAt
    }
  }
}
    `;
export type CreateSparkMutationFn = Apollo.MutationFunction<CreateSparkMutation, CreateSparkMutationVariables>;

/**
 * __useCreateSparkMutation__
 *
 * To run a mutation, you first call `useCreateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSparkMutation, { data, loading, error }] = useCreateSparkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      sparkCategoryId: // value for 'sparkCategoryId'
 *      visibility: // value for 'visibility'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCreateSparkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSparkMutation, CreateSparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSparkMutation, CreateSparkMutationVariables>(CreateSparkDocument, options);
      }
export type CreateSparkMutationHookResult = ReturnType<typeof useCreateSparkMutation>;
export type CreateSparkMutationResult = Apollo.MutationResult<CreateSparkMutation>;
export type CreateSparkMutationOptions = Apollo.BaseMutationOptions<CreateSparkMutation, CreateSparkMutationVariables>;
export const UpdateSparkDocument = gql`
    mutation UpdateSpark($id: ID!, $description: String, $sparkCategoryId: ID!, $visibility: String, $location: String) {
  updateSpark(
    id: $id
    description: $description
    sparkCategoryId: $sparkCategoryId
    visibility: $visibility
    location: $location
  ) {
    spark {
      id
      description
      sparkCategoryId
      categoryName
      visibility
      location
      userId
    }
  }
}
    `;
export type UpdateSparkMutationFn = Apollo.MutationFunction<UpdateSparkMutation, UpdateSparkMutationVariables>;

/**
 * __useUpdateSparkMutation__
 *
 * To run a mutation, you first call `useUpdateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSparkMutation, { data, loading, error }] = useUpdateSparkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      sparkCategoryId: // value for 'sparkCategoryId'
 *      visibility: // value for 'visibility'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateSparkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSparkMutation, UpdateSparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSparkMutation, UpdateSparkMutationVariables>(UpdateSparkDocument, options);
      }
export type UpdateSparkMutationHookResult = ReturnType<typeof useUpdateSparkMutation>;
export type UpdateSparkMutationResult = Apollo.MutationResult<UpdateSparkMutation>;
export type UpdateSparkMutationOptions = Apollo.BaseMutationOptions<UpdateSparkMutation, UpdateSparkMutationVariables>;
export const DestroySparkDocument = gql`
    mutation DestroySpark($id: ID!) {
  destroySpark(id: $id) {
    id
    errors
  }
}
    `;
export type DestroySparkMutationFn = Apollo.MutationFunction<DestroySparkMutation, DestroySparkMutationVariables>;

/**
 * __useDestroySparkMutation__
 *
 * To run a mutation, you first call `useDestroySparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySparkMutation, { data, loading, error }] = useDestroySparkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroySparkMutation(baseOptions?: Apollo.MutationHookOptions<DestroySparkMutation, DestroySparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroySparkMutation, DestroySparkMutationVariables>(DestroySparkDocument, options);
      }
export type DestroySparkMutationHookResult = ReturnType<typeof useDestroySparkMutation>;
export type DestroySparkMutationResult = Apollo.MutationResult<DestroySparkMutation>;
export type DestroySparkMutationOptions = Apollo.BaseMutationOptions<DestroySparkMutation, DestroySparkMutationVariables>;
export const SparksDocument = gql`
    query Sparks($first: Int!) {
  sparks(first: $first) {
    items {
      id
      description
      visibility
      location
      sparkCategoryId
      categoryName
      sparkReactionsCount
      userId
      userName
      userAvatar
      createdAt
    }
    nextCursor
    previousCursor
  }
}
    `;

/**
 * __useSparksQuery__
 *
 * To run a query within a React component, call `useSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparksQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSparksQuery(baseOptions: Apollo.QueryHookOptions<SparksQuery, SparksQueryVariables> & ({ variables: SparksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
      }
export function useSparksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SparksQuery, SparksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
        }
export function useSparksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SparksQuery, SparksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
        }
export type SparksQueryHookResult = ReturnType<typeof useSparksQuery>;
export type SparksLazyQueryHookResult = ReturnType<typeof useSparksLazyQuery>;
export type SparksSuspenseQueryHookResult = ReturnType<typeof useSparksSuspenseQuery>;
export type SparksQueryResult = Apollo.QueryResult<SparksQuery, SparksQueryVariables>;