import React, { useState, useEffect } from 'react';
import styles from './GraveAdministratorForm.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetch_request, request } from '../../../../../service/request';
import { Autocomplete } from '@mui/lab';
import TextField from '@mui/material/TextField';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { addGraveAdmin } from '../../../../../redux/actions';
import { makeStyles } from '@mui/styles';
import RemoveAdmin from '../RemoveAdmin /RemoveAdmin';

const useStyle = makeStyles(() => ({
  root: {
    paddingLeft: '0px',
  },
  rootCancel: {
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.161)',
    borderRadius: '10px',
    width: '97px',
    height: '37px',
    textTransform: 'none',
    color: '#999999',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    marginRight: '8px',
    '@media (max-width: 750px)': {
      marginRight: '27px',
    },
  },
  rootInvite: {
    backgroundColor: '#FEA65A',
    boxShadow: '0px 1px 6px rgba(254, 166, 90, 0.302)',
    borderRadius: '10px',
    textTransform: 'none',
    color: '#fff',
    width: '97px',
    height: '37px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  clear: {
    display: 'none',
  },
  hoverEffect: {
    '&:hover': {
      '& img': {
        display: 'flex!important',
      },
    },
  },
}));

const GraveAdministratorForm = ({ onClose, admins }) => {
  const classes = useStyle();
  const grave = useSelector((state) => state.grave.grave);
  const dispatch = useDispatch();
  const [admin, setAdmin] = useState({});
  const [userList, setUserList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const administratorsProps = {
    options: userList,
    getOptionLabel: (option) => (option?.user_name ? option.user_name : ''),
  };

  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  useEffect(() => {
    fetch_request(
      `/api/grave_users?grave_id=${grave.id}&&admin=${false}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          showUserNotification(data.errors, 'error');
        } else {
          setUserList(data);
        }
      })
      .catch((err) => console.log(err));
  }, [grave]);

  const handelAdmin = (value, id) => {
    if (!id) {
      showUserNotification('Chose a user from list', 'warning');
    } else {
      setIsSelected(false);
      const formDate = new FormData();
      formDate.append('[admin]', value);
      request(`/grave_administrators/${id}`, formDate, 'put')
        .then((res) => {
          if (res.data.grave_user.admin) {
            dispatch(addGraveAdmin(res.data.grave_user));
            showUserNotification('A new admin has been set', 'success');
          } else {
            dispatch(addGraveAdmin(res.data.grave_user));
            showUserNotification('Admin has been removed', 'success');
          }
        })
        .catch((err) => {
          showUserNotification(
            'Something went wrong, please try again later',
            'warning'
          );
          setIsSelected(true);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className={styles['label']}>Velg medlem</div>
      <div className={styles['administrators-content_input']}>
        <Autocomplete
          {...administratorsProps}
          id='auto-highlight'
          autoHighlight
          classes={{
            clearIndicator: classes.clear,
          }}
          onChange={(e, value) => {
            setAdmin(value);
            setIsSelected(true);
          }}
          popupIcon={
            <ExpandMoreOutlinedIcon className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled' />
          }
          className={styles['administrators-content_input_autocomplete']}
          renderInput={(params) => (
            <TextField placeholder='Søk på navn' {...params} margin='normal' />
          )}
        />
      </div>
      <div className={styles['grave-btn-container']}>
        <div className={styles['grave-information_content-verify']}>
          <button
            className={styles['rootDiscard']}
            onClick={() => {
              onClose();
            }}
          >
            Avbryt
          </button>
          <button
            className={
              isSelected ? styles['rootVerify'] : styles['disabledButton']
            }
            disabled={!isSelected}
            onClick={() => handelAdmin(true, admin.id)}
          >
            Lagre
          </button>
        </div>
      </div>
      <div className={styles['admin-wrapper']}>
        {admins.length > 0 &&
          admins.map((user, index) => {
            return (
              <RemoveAdmin user={user} key={index} handelAdmin={handelAdmin} />
            );
          })}
      </div>
    </div>
  );
};

export default GraveAdministratorForm;
