import React from 'react';
import styles from './AboutUser.module.css';
import SmallEdit from '../../../img/EditSmallIcon.svg';
import EditButton from '../../../components/EditButton/EditButton';
import UploadUserAvatar from './UploadUserAvatar';
import PrimaryButton from '../../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const AboutUser = ({ user }) => {
  const navigate = useNavigate();

  const navigateToOldPage = () => {
    navigate(`/member/${user.slug}`);
  };

  return (
    <div className={styles['container']}>
      <UploadUserAvatar user={user} />
      <div className={styles['sub-container']}>
        <div className={styles['user-info']}>
          <div className={styles['name']}>{user.full_name}</div>
          <div className={styles['country']}>{user.occupation}</div>
        </div>
        <PrimaryButton
          onClick={navigateToOldPage}
          children={<FormattedMessage id='Go to Old page' />}
        />
        {/* <div className={styles['edit']}>
          <EditButton onClick={() => {}} icon={SmallEdit} />
        </div> */}
      </div>
    </div>
  );
};

export default AboutUser;
