import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormattedMessage } from 'react-intl';
import WarningMessage from '../Input/WarningMessage';

const DatePickerInput = ({
  date,
  setDatePickerValue,
  disableFuture,
  disablePast,
  item,
}) => {
  const customDatePicker = {
    '& .MuiInputBase-root': {
      border: `1px solid ${item?.error ? '#c65c00' : 'transparent'} !important`,
      background: '#f5f5f5',
      padding: '11px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '12px',
      '&:hover': {
        borderColor: item?.error ? '#c65c00 !important' : '#36434b !important',
      },
    },
  };

  const [value, setValue] = useState(dayjs('YYYY-DD-MM'));
  useEffect(() => {
    if (date) {
      setValue(dayjs(date));
    }
  }, [date]);

  const handleDateChange = (newValue) => {
    if (newValue) {
      setValue(newValue);
      setDatePickerValue(newValue);
    } else {
      setDatePickerValue(null);
    }
  };

  const clearDate = (e) => {
    e.preventDefault();
    setValue(dayjs('YYYY-DD-MM'));
    setDatePickerValue(null);
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              variant: 'standard',
              fullWidth: true,
              sx: customDatePicker,
            },
          }}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
        {date && (
          <Button onClick={clearDate} color='#737a7f' type='text'>
            <FormattedMessage id='reset_date' />
          </Button>
        )}
      </LocalizationProvider>
      {item?.error && <WarningMessage messageKey={item.errorKey} />}
    </React.Fragment>
  );
};

export default DatePickerInput;
