import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SetRelationOrClaimDialog from '../RelationOrClaimModals/SetRelationOrClaimDialog';
import styles from './ConnectionRelation.module.css';
import X from '../../../../img/X.svg';

const useStyles = makeStyles(( ) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 8,
    outline: 'none',
    width: '600px',
    '@media (max-width: 800px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ConnectRelationsModal = (props) => {
  const classes = useStyles();

  return (
    <Modal
      open={props.openRelationDialog}
      onClose={props.handleCloseRelationDialog}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div className={styles['content_header']}>
          <span>Sett relasjon</span>
          <img
            loading='lazy'
            src={X}
            alt='x'
            onClick={() => props.handleCloseRelationDialog()}
          />
        </div>
        <SetRelationOrClaimDialog
          grave={props.grave}
          handleCloseRelationDialog={props.handleCloseRelationDialog}
          classes={{ paper: classes.confirmationPaper }}
        />
      </div>
    </Modal>
  );
};

export default ConnectRelationsModal;
