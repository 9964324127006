import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { SignInPage, NotFoundPage } from '../index';
import Registration from './Registration';
import { connect, useDispatch } from 'react-redux';
import animClasses from '../Router.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { subscriptionInvitationToken } from '../../redux/actions';

const AuthRouter = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const action = location.pathname.match(/([^*/]+)/g)[1];
  const screen = useSelector((state) => state.auth.currentScreen);
  const userSlug = localStorage.getItem('user-slug');
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  const slugRedirect = localStorage.getItem('slug_for_redirect');

  useEffect(() => {
    sessionStorage.setItem('previousPath', location.pathname);
  }, [location.pathname]);

  const redirectToLastPage = () => {
    const { location, navigate } = props;
    const trimmedToken = invitationToken.trim();
    const previousPath = sessionStorage.getItem('previousPath');

    if (
      slugRedirect !== 'null' &&
      slugRedirect !== null &&
      slugRedirect !== undefined
    ) {
      localStorage.setItem('slug_for_redirect', 'null');
      setTimeout(() => navigate(`/deceased/${slugRedirect}`), 1000);
    } else if (location.key) {
      if (trimmedToken === '') {
        if (
          previousPath &&
          (previousPath === '/registration' ||
            previousPath === '/auth/sign-in' ||
            previousPath === '/account-confirmation' ||
            previousPath === '/reset-password')
        ) {
          sessionStorage.removeItem('previousPath');
          navigate(`/${screen ? screen : ''}`);
        } else {
          navigate(-1);
        }
      } else {
        dispatch(subscriptionInvitationToken(''));
        navigate(`/member/${userSlug}`);
      }
    } else {
      navigate(`/${screen ? screen : ''}`);
    }
  };

  if (props.isAuthorized) {
    redirectToLastPage();
  }

  return (
    <React.Fragment>
      <Routes>
        <Route exact path='/auth/sign-in' element={<SignInPage />} />
        <Route exact path='/registration' element={<Registration />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps)(AuthRouter);
