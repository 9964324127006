import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Header.module.css';
import Categories from '../Components/Categories';

const Header = ({ sparkCategories, setSparkCategory, sparkCategory }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <FormattedMessage id='sparks' />
        </div>
        <Categories
          sparkCategories={sparkCategories}
          sparkCategory={sparkCategory}
          setSparkCategory={setSparkCategory}
        />
      </div>
      <div className={styles['description']}>
        <FormattedMessage id='sparks_description' />
      </div>
    </div>
  );
};

export default Header;
