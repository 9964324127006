import React, { useEffect, useState } from 'react';
import styles from './Workspace.module.css';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { request, fetch_request } from '../../../../service/request';
import { setGraveWorkplacesRedux } from '../../../../redux/actions';
import WorkspaceForm from './WorkspaceForm';
import SearchInput from '../../../../components/SearchInput';

const useStyle = makeStyles(() => ({
  tabContainer: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  indicator: {
    backgroundColor: '#985000',
  },
  tab: {
    flex: 1,
    marginRight: '70px',
    color: '#985000',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const Workspace = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [tabValue, setTabValue] = useState(0);
  const [workspaces, setWorkspaces] = useState([]);
  const [editWorkPlaceId, setEditWorkplaceId] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request('/grave_workplaces', { grave_id: props.graveInformation.id }, 'get')
      .then((response) => {
        setWorkspaces(response.data.grave_workplaces);
      })
      // .then((data) => {
      //   console.log(data);
      //   setWorkspaces(data.grave_workplaces);
      // })
      .catch((err) => {
        console.log(err);
      });
  }, [props.graveInformation.id]);

  const handelWorkSpaces = (value) => {
    setWorkspaces([...value]);
  };

  const saveWorkplaceRedux = (value) => {
    dispatch(setGraveWorkplacesRedux([...value]));
  };

  const handelCloseForm = () => {
    setOpenForm(false);
  };

  const handelEditWorkPlaceId = (value) => {
    if (value === editWorkPlaceId) {
      setEditWorkplaceId(null);
    } else {
      setEditWorkplaceId(value);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterItems = (searchItem) => {
    setTabValue(1);
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/grave_workplaces?grave_id=${props.graveInformation.id}&search=${searchItem}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setWorkspaces(data.grave_workplaces))
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles['workspace']}>
      <div className={styles['workspace-content']}>
        <SearchInput filterItems={filterItems} title='arbeidsplass' />
        <div className={classes.tabContainer}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='scrollable'
            id='id223223'
            aria-label='scrollable force tabs example'
            classes={{ indicator: classes.indicator }}
            style={{ width: '100%' }}
          >
            <Tab
              label='legg til arbeidsplass'
              {...a11yProps(0)}
              className={classes.tab}
            />
            <Tab
              label='alle arbeidsplasser'
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
        </div>
        {tabValue === 0 && (
          <WorkspaceForm
            workspaces={workspaces}
            handelWorkSpaces={handelWorkSpaces}
            saveWorkplaceRedux={saveWorkplaceRedux}
            workPlace={{}}
            workplaceId={null}
            graveId={props.graveInformation.id}
            disable={true}
            handelCloseForm={handelCloseForm}
            handelEditWorkPlaceId={handelEditWorkPlaceId}
            handleTabChange={() => setTabValue(1)}
            closeModal={props.closeModal}
          />
        )}
        <div className={styles['workspaces-list']}>
          {tabValue === 1 && (
            <>
              {workspaces.length > 0 ? (
                workspaces.map((workplace) => {
                  return (
                    <div
                      style={{
                        marginBottom: 32,
                        border: '1px solid #dfdfdf',
                        borderRadius: '10px',
                        padding: '24px',
                      }}
                      key={workplace.id}
                    >
                      <WorkspaceForm
                        workspaces={workspaces}
                        handelWorkSpaces={handelWorkSpaces}
                        saveWorkplaceRedux={saveWorkplaceRedux}
                        workPlace={workplace}
                        disable={editWorkPlaceId === workplace.id}
                        workplaceId={editWorkPlaceId}
                        graveId={props.graveInformation.id}
                        handelCloseForm={handelCloseForm}
                        handelEditWorkPlaceId={handelEditWorkPlaceId}
                        handleTabChange={() => setTabValue(1)}
                        closeModal={props.closeModal}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={styles['empty-workplaces-container']}>
                  <div className={styles['empty-workplace-wrapper']}>
                    <span>Arbeidsplass er ikke lagt til ennå.</span>
                    <button onClick={() => setTabValue(0)}>
                      Klikk her til en arbeidsplass
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default Workspace;
