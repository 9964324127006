import React from 'react';
import styles from './MenuForMobile.module.css';
import { FormattedMessage } from 'react-intl';

const MenuForMobile = ({ selectedMenuKey, setSelectedMenuKey }) => {
  const LifePageMenuItems = {
    1: {
      titleKey: 'sparks',
    },
    2: {
      titleKey: 'community',
    },
    3: {
      titleKey: 'purpose',
    },
    4: {
      titleKey: 'talents',
    },
  };
  return (
    <div className={styles['container']}>
      {Object.entries(LifePageMenuItems).map(([key, item]) => {
        const newKey = Number(key);
        return (
          <div
            key={key}
            className={
              selectedMenuKey === newKey
                ? `${styles['menu-item']} ${styles['active']}`
                : styles['menu-item']
            }
            onClick={() => setSelectedMenuKey(newKey)}
          >
            <div className={styles['item']}>
              <FormattedMessage id={item.titleKey} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MenuForMobile;
