import React, { useEffect, useState } from 'react';
import styles from './InvitesRelatives.module.css';
import { useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { setGraveUsersList } from '../../../../redux/actions';
import { fetch_request, request } from '../../../../service/request';
import {
  setGraveInvitationsList,
  getListOfCountries,
} from '../../../../redux/actions';
import GraveUserForm from '../GraveUserForm/GraveUserForm';
import SearchInput from '../../../../components/SearchInput';
import InviteRelativesForm from '../InviteRelativesForm/InviteRelativesForm';

const useStyle = makeStyles(() => ({
  tabContainer: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  indicator: {
    backgroundColor: '#985000',
  },
  tab: {
    flex: 1,
    marginRight: '70px',
    color: '#985000',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const InvitesRelatives = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [graveUsers, setGraveUsers] = useState([]);
  const [graveInvitations, setGraveInvitations] = useState([]);
  const [userId, setUserId] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [relationList, setRelationList] = useState([]);
  const [directionsList, setDirectionsList] = useState([]);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  // Get Relations
  useEffect(() => {
    request('/relations')
      .then((res) => setRelationList(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(`/api/graves/${props.grave.id}/grave_users`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setGraveUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.grave.id]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/graves/${props.grave.id}/grave_invited_users`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setGraveInvitations(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.grave.id, tabValue]);

  const handelGraveUsers = (value) => {
    setGraveUsers([...value]);
  };

  const saveGraveUsersRedux = (value) => {
    dispatch(setGraveUsersList([...value]));
  };

  const handelGraveInvitations = (value) => {
    setGraveInvitations([...value]);
  };

  const handelUserId = (value) => {
    if (value === userId) {
      setUserId(null);
    } else {
      setUserId(value);
    }
  };

  const handelInvitationId = (value) => {
    if (value === invitationId) {
      setInvitationId(null);
    } else {
      setInvitationId(value);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterUsers = (searchItem) => {
    setUserId(null);
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/graves/${props.grave.id}/grave_users?search=${searchItem}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setGraveUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterInvitations = (searchItem) => {
    setInvitationId(null);
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/graves/${props.grave.id}/grave_invited_users?search=${searchItem}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setInvitationId(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles['workspace']}>
      <div className={styles['workspace-content']}>
        <div className={classes.tabContainer}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='scrollable'
            id='id223223'
            aria-label='scrollable force tabs example'
            classes={{ indicator: classes.indicator }}
            style={{ width: '100%' }}
          >
            <Tab label='Inviter' {...a11yProps(0)} className={classes.tab} />
            <Tab label='Relasjoner' {...a11yProps(1)} className={classes.tab} />
            <Tab label='Ubesvarte' {...a11yProps(2)} className={classes.tab} />
          </Tabs>
        </div>
        {tabValue === 0 && (
          <div>
            <InviteRelativesForm
              graveInvitation={{}}
              graveInvitations={graveInvitations}
              invitationId={null}
              grave={props.grave}
              disable={true}
              handelInvitationId={handelInvitationId}
              handelGraveInvitations={handelGraveInvitations}
              handleTabChange={() => setTabValue(2)}
              relationList={relationList}
              directionsList={directionsList}
              currentSubscription={props.currentSubscription}
              onClose={props.onClose}
            />
          </div>
        )}
        <div className={styles['workspaces-list']}>
          {tabValue === 1 && (
            <>
              <SearchInput filterItems={filterUsers} title='slektning' />
              {graveUsers.length > 0 ? (
                graveUsers.map((graveUser, index) => {
                  return (
                    <div style={{ marginBottom: '32px' }} key={index}>
                      <GraveUserForm
                        showList={false}
                        relations={graveUsers}
                        handelRelations={handelGraveUsers}
                        saveRelationsRedux={saveGraveUsersRedux}
                        grave={props.grave}
                        relation={graveUser}
                        relationId={userId}
                        disable={userId === graveUser.id}
                        handelRelationId={handelUserId}
                        relationList={relationList}
                        directionsList={directionsList}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={styles['empty-workplaces-container']}>
                  <div className={styles['empty-workplace-wrapper']}>
                    <span>Bruker ikke funnet</span>
                  </div>
                </div>
              )}
            </>
          )}
          {tabValue === 2 && (
            <>
              <SearchInput filterItems={filterInvitations} title='inviterer' />
              {graveInvitations.length > 0 ? (
                graveInvitations.map((invitation, index) => {
                  return (
                    <div
                      style={{
                        marginBottom: 32,
                        border: '1px solid #dfdfdf',
                        borderRadius: '10px',
                        padding: '24px',
                      }}
                      key={index}
                    >
                      <InviteRelativesForm
                        graveInvitation={invitation}
                        graveInvitations={graveInvitations}
                        invitationId={invitationId}
                        grave={props.grave}
                        disable={invitationId === invitation.id}
                        handelInvitationId={handelInvitationId}
                        handelGraveInvitations={handelGraveInvitations}
                        handleTabChange={() => setTabValue(2)}
                        relationList={relationList}
                        directionsList={directionsList}
                        currentSubscription={props.currentSubscription}
                        onClose={props.onClose}
                      />
                    </div>
                  );
                })
              ) : (
                <div className={styles['empty-workplaces-container']}>
                  <div className={styles['empty-workplace-wrapper']}>
                    <span>Ingen aktive invitasjoner som ikke er besvart.</span>
                    <button
                      onClick={() => {
                        setTabValue(0);
                        setInvitationId(null);
                      }}
                    >
                      Klikk her for å invitere flere til graven
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default InvitesRelatives;
