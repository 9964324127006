import React, { useEffect } from 'react';
import styles from './SparksList.module.css';
import { Avatar, Tooltip } from '@mui/material';
import { timeSinceCreated } from '../../../../../../service/Utils';
import {
  useDestroySparkMutation,
  SparksDocument,
} from '../../../../../../graphql/generated/sparkHooks.ts';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import ActionButton from '../Components/ActionButton.jsx';
import Categories from '../Components/Categories.jsx';
import Visibility from '../Components/Visibility.jsx';
import { privacyList } from '../CreateSparks/CreateSparks.jsx';
import { FormattedMessage, useIntl } from 'react-intl';

const Header = ({
  spark,
  onClickEdit,
  editSpark,
  sparkCategories,
  setSparkCategory,
  privacy,
  setPrivacy,
  sparkCategory,
}) => {
  const intl = useIntl();
  const [destroySpark] = useDestroySparkMutation({
    refetchQueries: [
      {
        query: SparksDocument,
        variables: { first: 10 },
      },
    ],
  });

  const findPrivacy = async () => {
    if (spark.visibility) {
      const findPrivacy = await privacyList.find(
        (privacy) => privacy.key === spark.visibility
      );
      if (findPrivacy) {
        setPrivacy(findPrivacy);
      }
    }
  };

  useEffect(() => {
    findPrivacy();
  }, [spark]);

  const handleDestroySpark = async (id) => {
    try {
      await destroySpark({
        variables: {
          id,
        },
      });
      showUserNotification(
        intl.formatMessage({ id: 'spark_delete' }),
        'success'
      );
    } catch (err) {
      showUserNotification('Error destroying spark', 'error');
      console.error('Error destroying spark:', err);
    }
  };

  return (
    <div className={styles['header']}>
      <div className={styles['user-info']}>
        <Avatar src={spark?.userAvatar} className={styles['avatar']} />
        <div className={styles['user-header']}>
          <div className={styles['user-name']}>{spark?.userName}</div>
          <div
            className={
              !editSpark
                ? styles['user-name-container']
                : styles['user-name-container-edit']
            }
          >
            <div className={styles['create-at']}>
              {timeSinceCreated(spark?.createdAt)}
            </div>
            {spark.visibility && !editSpark && (
              <div className={styles['dot']} />
            )}
            {editSpark ? (
              <Visibility setPrivacy={setPrivacy} privacy={privacy} />
            ) : (
              spark.visibility && (
                <Tooltip title={<FormattedMessage id={privacy.key} />}>
                  {privacy.icon}
                </Tooltip>
              )
            )}
          </div>
        </div>
      </div>
      <div className={styles['action-container']}>
        {editSpark ? (
          <Categories
            sparkCategories={sparkCategories}
            setSparkCategory={setSparkCategory}
            sparkCategory={sparkCategory}
          />
        ) : (
          <div className={styles['category']}>
            <div className={styles['circle']} />
            <FormattedMessage id={spark.categoryName} />
          </div>
        )}
        <ActionButton
          onClickEdit={onClickEdit}
          onClickDelete={handleDestroySpark}
          spark={spark}
        />
      </div>
    </div>
  );
};

export default Header;
