import React from 'react';
import SparkPost from './SparkPost.jsx';

const SparksList = ({ sparksList, sparkCategories }) => {
  return sparksList.map((spark) => (
    <SparkPost key={spark.id} spark={spark} sparkCategories={sparkCategories} />
  ));
};

export default SparksList;
