import React from 'react';
import styles from './Admin.module.css';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(( ) => ({
  large: {
    width:  (14),
    height:  (14),
    marginBottom: '8px',
  },
}));

const Admin = ({ user }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div>
      <div
        className={styles['container']}
        onClick={() => navigate(`/member/${user.slug}`)}
      >
        <Avatar src={user.user_avatar} className={classes.large} />
        <div className={styles['user-name']}>{user.user_name}</div>
        <div className={styles['user-relation']}>{user.relation_name}</div>
        {user.grave_owner ? (
          <div className={styles['user-role']}>graveier</div>
        ) : user.admin ? (
          <div className={styles['user-role']}>Admin</div>
        ) : null}
      </div>
    </div>
  );
};

export default Admin;
