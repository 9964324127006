import React, { useRef, useState } from 'react'
import Cropper from "react-cropper";
import showUserNotification from "../../../components/UserNotification/showUserNotification";
import { makeFileFromURL } from "../../../service/makeFileFromBlob";
import { Dialog } from "@mui/material";
import ImageGallery from '../../../img/ImageGallery.svg';

const ImagePost = (props) => {
  const inputFile = useRef(null);
  const [openCropper, setOpenCropper] = useState(false);
  const handleCloseCropper = () => setOpenCropper(false);
  const [crop, setCrop] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const handleFileSelection = () => {
    inputFile.current.click();
  };

  const saveCroppedImage = async () => {
    const croppedImage = await makeFileFromURL(
      crop.getCroppedCanvas().toDataURL(),
      newImage.file?.name
    );
    props.setCroppedImageFile(croppedImage)
    props.setNewImage(newImage)
    props.setTempImage(crop.getCroppedCanvas().toDataURL());
    props.setCurrentFileType('image')
    handleCloseCropper();
  }

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener("load", async () => {
        if (file.size / (1024 * 1024) < 5) {
          setNewImage({ file, url: reader.result });
          setOpenCropper(true);
        } else {
          showUserNotification(
            "This file is too big! It should be less than 5MB!",
            "warning"
          );
        }
      });
    }
  };

  return (
    <>
      <img src={ImageGallery} alt="" className="ask-question-input-group_icons" onClick={handleFileSelection} />
      <input
        style={{ display: "none" }}
        ref={inputFile}
        type="file"
        accept="image/*"
        onChange={selectTheImage}
      />
      <Dialog open={openCropper} onClose={handleCloseCropper}>
        <Cropper
          style={{
            height: "450px",
            width: "100%",
            minHeight: 300,
            minWidth: 300,
          }}
          src={newImage?.url}
          initialAspectRatio={1}
          viewMode={1}
          guides={true}
          zoomOnWheel={false}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          aspectRatio={4 / 3}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCrop(instance);
          }}
        />
        <div className="settings-page_header_cropper-buttons">
          <button
            className="settings-page_header_cropper-buttons_button cancel-b"
            onClick={handleCloseCropper}
          >
            Cancel
          </button>
          <button
            className="settings-page_header_cropper-buttons_button save-b"
            onClick={saveCroppedImage}
          >
            Save
          </button>
        </div>
      </Dialog>
    </>
  )
};

export default ImagePost;