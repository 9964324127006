import "./AskQuestion.css";
import { TextareaAutosize } from "@mui/material";
import React, { useState } from "react";
import ImagePost from "./ImagePost";
import BinIcon from "../../../img/BinIcon.svg";
import { request } from "../../../service/request";
import UserInfoHeader from "./UserInfoHeader";
import VideoPost from "./VideoPost";
import ReactPlayer from "react-player";
import showUserNotification from "../../../components/UserNotification/showUserNotification";
import AudioPost from "./AudioPost";
import MusicPlayer from "./MusicPlayer";
import { useDispatch } from "react-redux";
import { addQuestion } from "../../../redux/actions";

const AskQuestion = (props) => {
  const loggedInUser = props.loggedInUser;
  const [text, setText] = useState("");
  const [currentFileType, setCurrentFileType] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [tempImage, setTempImage] = useState("");
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [audio, setAudio] = useState(null);
  const [privacy, setPrivacy] = useState('public')
  const dispatch = useDispatch();

  const isMediaUploaded = () => {
    return currentFileType !== "";
  };

  const submitQuestion = async () => {
    await request("/user_wall_posts", buildRequest(), "post")
      .then((res) => {
        fetchNewDataAndDispatch(res.data);
        resetAllStates();
        showUserNotification("Spørsmålet ble sendt", "success");
        props.setStartRefresh(true);
      })
      .catch((res) => {
        showUserNotification(
          "Unable to ask question... Please try again later",
          "error"
        );
      });
  };

  const fetchNewDataAndDispatch = async (res) => {
    await request(`/user_wall_posts/${res.id}`, null, "get")
      .then((res) => {
        dispatch(addQuestion([res.data]));
      })
      .catch((res) => {
        showUserNotification(
          "Unable to ask question... Please try again later",
          "error"
        );
      });
  };
  const buildRequest = () => {
    const formdata = new FormData();
    formdata.append("slug", props.user.slug);
    formdata.append("question", text);
    formdata.append("privacy_setting", privacy)

    switch (currentFileType) {
      case "image":
        formdata.append("file", croppedImageFile, newImage.file?.name);
        formdata.append("file_type", currentFileType);
        break;
      case "video":
        formdata.append("external_url", videoLink);
        formdata.append("file_type", currentFileType);
        break;
      case "audio":
        formdata.append("file", audio.file, audio.file?.name);
        formdata.append("file_type", currentFileType);
        break;
      default:
        break;
    }
    return formdata;
  };

  const isTextAdded = () => {
    return text;
  };

  const handleImageClear = () => {
    setTempImage("");
    setNewImage(null);
    setCroppedImageFile(null);
    setCurrentFileType("");
  };

  const resetAllStates = () => {
    setText("");
    setCurrentFileType("");
    setNewImage(null);
    setTempImage("");
    setCroppedImageFile(null);
    setVideoLink("");
    setAudio(null);
  };

  const handleVideoClear = () => {
    setVideoLink("");
    setCurrentFileType("");
  };

  return (
    <div className="ask-question-box-container">
      <UserInfoHeader author={loggedInUser} showPrivacy={true} setPrivacy={setPrivacy} privacy={privacy}/>
      {tempImage && (
        <div className="image-post-wrapper">
          <img src={tempImage} alt="" className="image-post" />
          <img
            src={BinIcon}
            className="bin-icon"
            alt=""
            onClick={handleImageClear}
          />
        </div>
      )}

      {videoLink && currentFileType === "video" && (
        <div className="video-player-wrapper">
          <div className="video-cancel-selection">
            <img
              src={BinIcon}
              className="bin-icon"
              alt=""
              onClick={handleVideoClear}
            />
          </div>
          <ReactPlayer
            url={videoLink}
            height="200px"
            width="360px"
            style={{ margin: "auto" }}
            light={true}
          />
        </div>
      )}

      {audio && currentFileType === "audio" && <MusicPlayer file={audio.url} />}
      <div className="text-area-wrapper">
        <TextareaAutosize
          value={text}
          onChange={(e) => {
            e.target.value[0] !== " " && setText(e.target.value);
          }}
          placeholder='Start spørsmålet ditt med "Hva", "Hvordan", "Hvorfor", etc'
          className="ask-question-text-area"
        ></TextareaAutosize>
      </div>
      <div className="footer-wrapper">
        {!isMediaUploaded() && (
          <div className="ask-question-input-group">
            <ImagePost
              setTempImage={setTempImage}
              setNewImage={setNewImage}
              setCurrentFileType={setCurrentFileType}
              setCroppedImageFile={setCroppedImageFile}
            />
            <VideoPost
              setVideoLink={setVideoLink}
              setCurrentFileType={setCurrentFileType}
            />
            <AudioPost
              setAudio={setAudio}
              setCurrentFileType={setCurrentFileType}
            />
          </div>
        )}
        <button
          className="submit-question_button"
          onClick={submitQuestion}
          disabled={!isTextAdded()}
        >
          Send spørsmål
        </button>
      </div>
    </div>
  );
};

export default AskQuestion;
