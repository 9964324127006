import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CONSTANTS } from '../../service/constants';
import ProcessModal from '../../containers/Payment/atoms/ProcessModal';
import Success from './Success';
import Error from './Error';

const AccountConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const userSlug = localStorage.getItem('user-slug');

  const getUrl = () => {
    if (userSlug) {
      return '/settings';
    } else {
      return '/auth/sign-in';
    }
  };

  const redirectKey = userSlug ? 'goTo_settings_page' : 'auth_login';

  const createVerification = async () => {
    setLoading(true);
    const data = {
      token,
    };
    axios
      .post(`${CONSTANTS.baseUrl}/api/account_confirmations`, data)
      .then((response) => {
        setLoading(false);
        setIsVerified(true);
      })
      .catch((error) => {
        setLoading(false);
        setIsVerified(false);
      });
  };

  useEffect(() => {
    if (token) {
      createVerification();
    } else {
      navigate('/');
    }
  }, [token]);

  if (loading) {
    return (
      <ProcessModal open={loading} messageKey='account_verification_waiting' />
    );
  }

  return isVerified ? (
    <Success url={getUrl()} redirectKey={redirectKey} />
  ) : (
    <Error
      headingKey='account_verification_heading_error'
      descriptionKey='account_verification_description_error'
      url={getUrl()}
      redirectKey={redirectKey}
    />
  );
};

export default AccountConfirmation;
