import React from 'react';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import X from '../img/X.svg';
import styles from './CustomModal.module.css';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: '32px',
    height: 'maxContent',
    backgroundColor: 'white',
    borderRadius: '10px',
    outline: 'none',
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width :520px)': {
      width: '100%',
      borderRadius: '0px',
      // maxHeight: '100vh',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const CustomModal = ({ isOpen, children, closeModal, title, style }) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent} style={style}>
        <div className={styles['downgrade-plan-prompt_body_subheading']}>
          {title}
        </div>
        <img
          src={X}
          onClick={() => closeModal(false)}
          alt=''
          className={styles['downgrade-plan-prompt_cancel']}
        />
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
