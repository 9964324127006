import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import styles from './SparksSubMenu.module.css';
import Sparks from './Sparks/CreateSparks/Sparks';

const SparksSubMenu = ({ user }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(null);
  const menuItems = {
    1: {
      titleKey: 'sparks',
    },
    2: {
      titleKey: 'graves',
    },
    3: {
      titleKey: 'wisdom',
    },
  };

  const handleMenuClick = (key) => {
    setSelectedMenuKey(key === selectedMenuKey ? null : key);
  };

  return (
    <div className={styles['container']}>
      {Object.entries(menuItems).map(([key, item]) => {
        const newKey = Number(key);
        return (
          <React.Fragment key={key}>
            <div
              className={styles['menu-item']}
              onClick={() => handleMenuClick(newKey)}
            >
              <div className={styles['item']}>
                <FormattedMessage id={item.titleKey} />
              </div>
              <ArrowRightIcon
                style={{ rotate: selectedMenuKey === newKey ? '90deg' : '' }}
              />
            </div>
            {newKey === 1 && selectedMenuKey === newKey && (
              <Sparks user={user} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SparksSubMenu;
