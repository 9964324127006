import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './AccountConfirmation.module.css';
import { useNavigate } from 'react-router-dom';

const Messages = ({
  headingKey,
  descriptionKey,
  redirectKey = 'auth_login',
  url = '/auth/sign-in',
}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={styles['heading']}>
        <FormattedMessage id={headingKey} />
      </div>
      <div className={styles['description']}>
        <FormattedMessage id={descriptionKey} />{' '}
        <span className={styles['login']} onClick={() => navigate(url)}>
          <FormattedMessage id={redirectKey} />
        </span>
      </div>
    </React.Fragment>
  );
};

export default Messages;
