import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './MenuForDesktop.module.css';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';

const MenuForDesktop = ({ setSelectedMenuKey, selectedMenuKey }) => {
  const LifePageMenuItems = {
    1: {
      titleKey: 'sparks',
      icon: <AutoAwesomeOutlinedIcon />,
    },
    2: {
      titleKey: 'community',
      icon: <PeopleOutlineIcon />,
    },
    3: {
      titleKey: 'purpose',
      icon: <WbSunnyOutlinedIcon />,
    },
    4: {
      titleKey: 'talents',
      icon: <ColorizeOutlinedIcon />,
    },
  };

  return (
    <div className={styles['container']}>
      {Object.entries(LifePageMenuItems).map(([key, item]) => {
        const newKey = Number(key);
        return (
          <div
            onClick={() => setSelectedMenuKey(newKey)}
            key={key}
            className={
              selectedMenuKey === newKey
                ? `${styles['menu-item']} ${styles['active']}`
                : styles['menu-item']
            }
          >
            <div className={styles['icon']}>{item.icon}</div>
            <div className={styles['menu']}>
              <FormattedMessage id={item.titleKey} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MenuForDesktop;
