import React, { useState } from 'react';
import styles from './EditVirtue.module.css';
import X from '../../../../img/X.svg';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import { Button, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { request } from '../../../../service/request';
import { useSelector } from 'react-redux';

const useStyle = makeStyles(() => ({
  root: {
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
    border: 'none !important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#777777 !important',
    textTransform: 'none',
    width: '119px',
    height: '39px',
    marginRight: '20px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootClicked: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: '1px solid #FEA65A',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FEA65A',
    textTransform: 'none',
    width: '119px',
    height: '39px',
    marginRight: '20px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
  rootSave: {
    width: '180px',
    height: '55px',
    color: '#fff',
    backgroundColor: '#FEA65A',
    borderRadius: '10px',
    boxShadow: '0px 1px 6px rgba(254, 166, 90, 0.302)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
      boxShadow: '0px 2px 12px rgba(254, 166, 90, 0.302)',
    },
  },
}));

const EditVirtue = (props) => {
  const classes = useStyle();
  const grave = useSelector((state) => state.grave.grave);
  const [privacy, setPrivacy] = useState(props.chosenSuggestion.privacy);

  const [chosenSuggestion, setChosenSuggestion] = useState(
    props.chosenSuggestion
  );
  const [story, setStory] = useState(props.chosenSuggestion.comment);
  const saveChanges = async () => {
    var formdata = new FormData();
    formdata.append('grave_personality[grave_id]', grave.id);
    formdata.append('grave_personality[comment]', story);
    formdata.append(
      'grave_personality[personality_id]',
      chosenSuggestion.personality_id
    );
    formdata.append('grave_personality[privacy_attributes][setting]', privacy);
    // privacy !== " " && formdata.append("grave_personality[privacy_attributes][setting]", privacy);
    request(
      `/grave_personalities/${props.chosenSuggestion.id}`,
      formdata,
      'put'
    )
      .then((res) => {
        if (res.status === 200) {
          props.onEdit(res.data);
          props.closeModal();
        }
      })
      .catch((err) => console.log(err));
  };

  const updatePrivacy = (e) => {
    setPrivacy(e.target.value);
  };

  return (
    <div className={styles['edit-virtue']}>
      <div className={styles['edit-virtue_content']}>
        <div className={styles['edit-virtue_content-header']}>
          <span>Rediger dyd</span>
          <img loading='lazy' src={X} alt='x' onClick={props.closeModal} />
        </div>

        <div className={styles['edit-virtue_content-suggestions']}>
          <Button
            variant='outlined'
            onClick={() => {
              setChosenSuggestion(chosenSuggestion);
              setStory(chosenSuggestion.comment);
            }}
            className={classes.rootClicked}
          >
            {chosenSuggestion.personality}
          </Button>
        </div>

        <div className={styles['edit-virtue_content-subheader']}>
          <span>
            Du minner {grave.name} som{' '}
            <span>en {chosenSuggestion.personality}</span> person.
          </span>
        </div>
        <div className={styles['edit-virtue_content-textarea']}>
          <textarea
            value={story}
            onChange={(e) => setStory(e.target.value)}
            rows={5}
          />
        </div>
        <Select
          value={privacy}
          onChange={updatePrivacy}
          style={{ margin: '0 auto 0 0' }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        >
          {/*<MenuItem value={null} disabled = {true}>No Privacy Set</MenuItem>*/}
          <MenuItem value='public'>
            <img
              loading='lazy'
              src={Everyone}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
            />
            Alle
          </MenuItem>
          <MenuItem value='relatives'>
            <img
              loading='lazy'
              src={FamilySilhouette}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
            />
            Familie
          </MenuItem>
          <MenuItem value='private'>
            <img
              loading='lazy'
              src={OnlyMe}
              alt='Family Silhouette'
              className={styles['memories-header_right-icon']}
              style={{ marginLeft: '3px' }}
            />
            Privat
          </MenuItem>
        </Select>
        <div className={styles['edit-virtue_content-button']}>
          <Button
            variant='contained'
            className={classes.rootSave}
            onClick={saveChanges}
          >
            Lagre
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditVirtue;
