import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Personality from '../GraveContent/Personality';
import VirtueModal from '../VirtueComponent/VirtueModal';
import EditVirtue from '../VirtueComponent/EditVirtue';
import DeleteVirtue from '../VirtueComponent/DeleteVirtue';
import {
  deleteVirtueRedux,
  editVirtueRedux,
  setVirtuesRedux,
} from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { request } from '../../../../service/request';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';

const useStyles = makeStyles(( ) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '700px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '10px',
    outline: 'none',
    '@media (max-width: 800px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const PersonalityWrapper = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const grave = props.grave;
  const isAuth = props.isAuth;
  const can_add_virtue = grave?.can_add_virtue;
  const virtues = useSelector((state) => state.grave.grave.virtues) || [];
  const [openVirtueModal, setOpenVirtueModal] = useState(false);
  const [openEditVirtue, setOpenEditVirtue] = useState(false);
  const [openDeleteVirtueModal, setOpenDeleteVirtueModal] = useState(false);
  const [indexOfChosenSuggestion, setIndexOfChosenSuggestion] = useState();
  const [chosenSuggestion, setChosenSuggestion] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBillingMenu, setOpenBillingMenu] = useState(false);
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const canAddPersonality = useSelector(
    (state) => state.user.activeSubscription?.can_add_grave_personalities
  );

  const handleOpenVirtueModal = () => {
    if (canAddPersonality) {
      setOpenVirtueModal(true);
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleCloseVirtueModal = () => setOpenVirtueModal(false);
  const handleOpenEditVirtueModal = () => {
    setOpenEditVirtue(true);
    handleCloseMenu();
  };
  const handleCloseEditVirtueModal = () => {
    setOpenEditVirtue(false);
    handleCloseMenu();
  };
  const handleOpenDeleteVirtueModal = () => {
    setOpenDeleteVirtueModal(true);
    handleCloseMenu();
  };
  const handleCloseDeleteVirtueModal = () => {
    setOpenDeleteVirtueModal(false);
    handleCloseMenu();
  };

  useEffect(() => {
    (async () => {
      isAuth && localStorage.token && (await refreshVirtueInformation());
    })();
  }, []);

  const refreshVirtueInformation = async () => {
    try {
      const response = await request(
        `/grave_personalities?grave_personality[grave_id]=${grave.id}`
      );
      dispatch(setVirtuesRedux(response.data.grave_personalities));
    } catch (err) {
      console.log(err);
    }
  };

  const editVirtue = (virtue) => {
    dispatch(editVirtueRedux(virtue));
    setChosenSuggestion(virtue);
  };

  const deleteVirtue = (virteId) => {
    dispatch(deleteVirtueRedux(virteId));
    setChosenSuggestion(null);
    setIndexOfChosenSuggestion(null);
  };

  useEffect(() => {
    function findUser(suggestion) {
      return grave?.grave_users?.filter(
        (user) => user.user_id === suggestion.user.id
      )[0];
    }
    return virtues?.forEach(
      (vir) => (vir.user.relation = findUser(vir)?.relation)
    );
  }, [virtues]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenBillingMenu(false);
  };

  return (
    <>
      <Modal
        open={openVirtueModal}
        onClose={handleCloseVirtueModal}
        className={classes.modal}
        BackdropProps={{
          className: classes.modalBackground,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className={classes.modalContent}>
          <VirtueModal
            refreshVirtueInformation={refreshVirtueInformation}
            closeModal={handleCloseVirtueModal}
            suggestions={virtues}
          />
        </div>
      </Modal>
      <Modal
        open={openEditVirtue}
        onClose={handleCloseEditVirtueModal}
        className={classes.modal}
        BackdropProps={{
          className: classes.modalBackground,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className={classes.modalContent}>
          <EditVirtue
            onEdit={editVirtue}
            closeModal={handleCloseEditVirtueModal}
            chosenSuggestion={chosenSuggestion}
          />
        </div>
      </Modal>
      <Modal
        open={openDeleteVirtueModal}
        onClose={handleCloseDeleteVirtueModal}
        className={classes.modal}
        BackdropProps={{
          className: classes.modalBackground,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className={classes.modalContent}>
          <DeleteVirtue
            closeModal={handleCloseDeleteVirtueModal}
            suggestions={virtues}
            chosenSuggestion={chosenSuggestion}
            delete={deleteVirtue}
            graveName={grave?.name}
          />
        </div>
      </Modal>
      <Personality
        isAuth={isAuth}
        grave={grave}
        suggestions={virtues}
        handleOpenVirtueModal={handleOpenVirtueModal}
        indexOfChosenSuggestion={indexOfChosenSuggestion}
        setIndexOfChosenSuggestion={setIndexOfChosenSuggestion}
        chosenSuggestion={chosenSuggestion}
        setChosenSuggestion={setChosenSuggestion}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        openBillingMenu={openBillingMenu}
        setOpenBillingMenu={setOpenBillingMenu}
        handleCloseMenu={handleCloseMenu}
        handleOpenEditVirtueModal={handleOpenEditVirtueModal}
        handleOpenDeleteVirtueModal={handleOpenDeleteVirtueModal}
        can_add_virtue={can_add_virtue}
        saveGraveToRedirect={props.saveGraveToRedirect}
      />
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        handleCloseUpgradePlansModal={() => setOpenUpgradePlanModal(false)}
        body='Så fint at du ønsker å legge til verdier. For å legge til trenger du familie eller familie + medlemskap'
        title='Oppdater medlemskap'
      />
    </>
  );
};

export default PersonalityWrapper;
