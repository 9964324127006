import React, { useState, useEffect } from 'react';
import { request } from '../../service/request';
import { useParams } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import NotFoundPage from '../404/404';
import UserHeader from './Components/UserHeader';
import styles from './NewUserProfile.module.css';
import { setInformationAboutUser } from '../../redux/actions';

const NewUserProfile = () => {
  const dispatch = useDispatch();
  let { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const loggedInUser = useSelector((state) => state.user.user);

  useEffect(() => {
    if (slug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      request(`/users/${slug}`, requestOptions, 'get')
        .then((response) => {
          dispatch(setInformationAboutUser(response.data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          showUserNotification(err.response.data.error || err.message, 'error');
          setLoading(false);
        });
    }
  }, [slug]);

  if (isEmpty(loggedInUser) && !loading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['user-profile']}>
      <UserHeader user={loggedInUser} />
    </div>
  );
};

export default NewUserProfile;
