export const isVideoScrolledIntoView = (el) => {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;

  var isVisible = elemTop <= 500 && elemTop >= 100;

  return isVisible;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const getSubscriptionDiscount = (discounts, members) => {
  const sortedDiscounts = discounts.sort((a, b) => a.user_count - b.user_count);

  const maxDiscountObject = sortedDiscounts[sortedDiscounts.length - 1];
  const maxUserCount = maxDiscountObject.user_count;

  if (members > maxUserCount) {
    return maxDiscountObject;
  }

  const foundDiscount = sortedDiscounts.find(
    (discount) => discount.user_count === members
  );

  return foundDiscount
    ? foundDiscount
    : { user_count: members, discount_percentage: 0 };
};

export const timeSinceCreated = (createdAt) => {
  if (createdAt === null || createdAt === undefined) {
    return '';
  }

  const createdDate = new Date(createdAt);
  const currentDate = new Date();

  if (isNaN(createdDate.getTime())) {
    return '';
  }

  const timeDifference = currentDate - createdDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);

  if (minutes <= 1) {
    return 'Just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
      return `About ${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (createdDate.getFullYear() === currentDate.getFullYear()) {
      const options = { day: 'numeric', month: 'long' };
      return createdDate.toLocaleDateString('en-US', options);
    } else {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return createdDate.toLocaleDateString('en-US', options);
    }
  }
};
