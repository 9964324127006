import React, { useState } from 'react';
import styles from './Vipps.module.css';
import isEmpty from 'lodash.isempty';
import PlansDescription from '../PlansDescription/PlansDescription';
import {
  setPaymentMethods,
  setSelectedPlan,
  setSubscriptionCount,
  setReturnPage,
  setPaymentMethod,
  setShowCardMethod,
  setSelectedCardMethod,
  setBillingInformation,
  setSelectedVippsMethod,
  setShowVippsPaymentMethod,
  setVippsPaymentMethods,
  setVippsPhoneNumber,
  setSubscriptionPeriod,
} from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTANTS, ProductKeys } from '../../../../service/constants';
import { useNavigate } from 'react-router-dom';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import VippsPhoneNumber from './VippsPhoneNumber';
import PlanPaymentDetails from '../PlanPaymentDetails/PlanPaymentDetails';
import ProcessModal from '../ProcessModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { createAgreement } from './CreateAgreement';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentType } from '../../../../service/constants';
import { updateSubscription } from '../../../../service/updateSubscription';

const Vipps = ({
  discountsList,
  subscriptionPeriods,
  plans,
  selectedPlan,
  currentSubscription,
  selectedPaymentMethod,
  defaultPlan,
  currentSubscriptionPlan,
  agreementHash,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedSubscriptionPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );
  const vippsPhoneNumber = useSelector(
    (state) => state.paymentMethods.vippsPhoneNumber
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const subscriptionCount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalCount
  );
  const amount = useSelector(
    (state) => state.subscriptionDetails.subscriptionTotalPrice
  );
  const selectedVippsMethod = useSelector(
    (state) => state.paymentMethods.selectedVippsMethod
  );
  const vippsPaymentMethods = useSelector(
    (state) => state.paymentMethods.vippsPaymentMethods
  );
  const returnPage = useSelector((state) => state.returnPage);
  const showCard = useSelector(
    (state) => state.paymentMethods.showVippsPaymentMethod
  );

  const handleShowModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleShowPayment = (method) => {
    if (!isEmpty(method)) {
      dispatch(setSelectedVippsMethod(method));
      dispatch(setVippsPhoneNumber(method.phone_number));
    } else {
      dispatch(setSelectedVippsMethod(method));
      dispatch(setVippsPhoneNumber(''));
    }
  };
  const handleShowCard = (value) => dispatch(setShowVippsPaymentMethod(value));
  const handleProductChosen = (plan) => {
    if (plan.key === ProductKeys.free) {
      navigate(`/pricing/payment`);
    } else {
      navigate(`/pricing/payment?payment_method_type=${selectedPaymentMethod}`);
    }
    dispatch(setSelectedPlan(plan));
  };

  const resetState = () => {
    dispatch(setShowCardMethod(false));
    dispatch(setShowVippsPaymentMethod(false));
    dispatch(setBillingInformation({}));
    dispatch(setPaymentMethod({}));
    dispatch(setSelectedVippsMethod({}));
    dispatch(setVippsPaymentMethods([]));
    dispatch(setPaymentMethods([]));
    dispatch(setSelectedCardMethod('vipps'));
    dispatch(setReturnPage(null));
    dispatch(setVippsPhoneNumber(''));
    dispatch(setSubscriptionPeriod({}));
    dispatch(setSubscriptionCount(1));
  };

  const handleVippsAgreement = (response) => {
    if (response.vippsConfirmationUrl) {
      window.location.href = response.vippsConfirmationUrl;
      resetState();
      handleCloseModal();
    } else {
      handleCloseModal();
      setErrorMessage(intl.formatMessage({ id: 'something_wrong' }));
      setOpenErrorModal(true);
    }
  };

  const handelCreateAgreement = () => {
    const token = localStorage.getItem('token');
    handleShowModal();
    createAgreement(
      vippsPhoneNumber,
      selectedPlan.id,
      subscriptionCount,
      token,
      selectedSubscriptionPeriod.key,
      selectedSubscriptionPeriod.discount
    )
      .then((agreement) => {
        handleCloseModal();
        localStorage.setItem('agreementId', agreement.agreementId);
        handleVippsAgreement(agreement);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.message);
        handleCloseModal();
        setOpenErrorModal(true);
      });
  };

  const handleUpdateSubscription = async () => {
    handleShowModal();
    const url = `${CONSTANTS.baseUrl}/api/subscriptions/${currentSubscription.id}.json`;
    const token = localStorage.getItem('token');
    const params = {
      payment_method_id: selectedVippsMethod.id,
      user_count: parseInt(subscriptionCount),
      product_id: selectedPlan.id,
      period: selectedSubscriptionPeriod.key,
    };

    const result = await updateSubscription(url, token, params);

    if (result.success) {
      handleCloseModal();
      handleServerResponse(result.data);
    } else {
      showUserNotification(result.error, 'error');
      handleCloseModal();
      setErrorMessage(result.error);
      setOpenErrorModal(true);
    }
  };

  const getMessageKey = () => {
    switch (currentSubscriptionPlan.key) {
      case ProductKeys.free:
        return 'settings_subscribedPlan';
      case ProductKeys.conscious:
        if (selectedPlan.key === ProductKeys.conscious_plus) {
          return 'settings_updatePlan';
        }
        if (selectedPlan.key === currentSubscriptionPlan.key) {
          return 'settings_addedMember';
        }
        return 'settings_updatePlan';
      case ProductKeys.conscious_plus:
        if (selectedPlan.key === ProductKeys.conscious) {
          return 'settings_downgradePlan';
        }
        if (selectedPlan.key === currentSubscriptionPlan.key) {
          return 'settings_addedMember';
        }
        return 'settings_updatePlan';
      default:
        return 'settings_subscribedPlan';
    }
  };

  const handleServerResponse = (response) => {
    if (response.error) {
      handleCloseModal();
      setOpenErrorModal(true);
    } else {
      let url = returnPage.url || '/settings/subscriptions';
      if (subscriptionCount > 1) {
        url = returnPage.url || '/settings/subscription-invitations';
      }
      let messageKey = getMessageKey();
      if (url) {
        dispatch(setShowCardMethod(false));
        dispatch(setShowVippsPaymentMethod(false));
        dispatch(setBillingInformation({}));
        dispatch(setPaymentMethod({}));
        dispatch(setSelectedVippsMethod({}));
        dispatch(setVippsPaymentMethods([]));
        dispatch(setPaymentMethods([]));
        dispatch(setSelectedCardMethod('vipps'));
        dispatch(setReturnPage(null));
        dispatch(setVippsPhoneNumber(''));
        dispatch(setSubscriptionPeriod({}));
      }
      navigate(url, { show: 1, key: messageKey });
    }
  };

  const onNext = () => {
    if (isEmpty(selectedPlan)) {
      showUserNotification(intl.formatMessage({ id: 'choose_plan' }), 'error');
      return;
    }

    if (vippsPhoneNumber.trim().length !== 8) {
      setError(true);
      return;
    }

    const hasSelectedVippsMethod = !isEmpty(selectedVippsMethod);
    const isActiveAgreement =
      agreementHash[selectedVippsMethod?.agreement_id]?.status === 'active';

    if (hasSelectedVippsMethod && isActiveAgreement) {
      handleUpdateSubscription();
    } else {
      handelCreateAgreement();
    }
  };

  const handlePhoneNumberChange = (value) => {
    dispatch(setVippsPhoneNumber(value));
    if (value.trim().length === 8 || value.trim() === '') {
      setError(false);
    } else {
      handleShowPayment({});
    }
  };

  const getAgreementStatus = (paymentMethod) => {
    if (
      !isEmpty(paymentMethod) &&
      !isEmpty(agreementHash) &&
      paymentMethod.payment_type === PaymentType.vipps
    ) {
      return agreementHash[paymentMethod.agreement_id]
        ? agreementHash[paymentMethod.agreement_id].status
        : null;
    } else {
      return null;
    }
  };

  const handelSelectPaymentMethod = (method) => {
    handleShowPayment(method);
  };

  return (
    <React.Fragment>
      <div className={styles['payment-page-container']}>
        <div className={styles['payment-container']}>
          {vippsPaymentMethods.length > 0 &&
            vippsPaymentMethods.map((method) => (
              <PaymentMethods
                method={method}
                showPayment={method.id === selectedVippsMethod?.id}
                handelSelectPaymentMethod={handelSelectPaymentMethod}
                key={method.id}
                getAgreementStatus={getAgreementStatus}
              />
            ))}
          {/* {showCard && ( */}
          <div className={styles['payment-details']}>
            <React.Fragment>
              <PlanPaymentDetails
                subscriptionPeriods={subscriptionPeriods}
                handleProductChosen={handleProductChosen}
                chosenPlan={defaultPlan}
                plans={plans}
                isCurrentPlan={selectedPlan.key === currentSubscriptionPlan.key}
                discountsList={discountsList}
              />
              <VippsPhoneNumber
                selectedMethod={selectedVippsMethod}
                vippsPhoneNumber={vippsPhoneNumber}
                handelOnChange={handlePhoneNumberChange}
                error={error}
              />
              <div className={styles['btn-container']}>
                <button className={styles['btn']} onClick={onNext}>
                  <FormattedMessage id='auth_next' />
                </button>
              </div>
              <PlansDescription
                amount={amount}
                subscriptionPeriod={selectedSubscriptionPeriod}
                chosenPlan={defaultPlan}
              />
            </React.Fragment>
          </div>
          {/* )} */}
        </div>
        {openModal && (
          <ProcessModal open={openModal} messageKey={'payment_progress'} />
        )}
        {openErrorModal && (
          <ErrorModal
            message={errorMessage}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Vipps;
