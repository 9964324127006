import React, { useState } from 'react';
import styles from './Sparks.module.css';
import Header from './Header.jsx';
import User from './User.jsx';
import TextArea from './TextArea.jsx';
import Footer from './Footer.jsx';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification.js';
import CropperModal from '../../../CropperModal/CropperModal.jsx';
import SelectedImage from '../SelectedMedia/SelectedImage.jsx';
import SelectedVideo from '../SelectedMedia/SelectedVideo.jsx';
import SelectedAudio from '../SelectedMedia/SelectedAudio.jsx';
import {
  useCreateSparkMutation,
  SparksDocument,
} from '../../../../../../graphql/generated/sparkHooks.ts';
import PublicIcon from '@mui/icons-material/Public';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { FormattedMessage, useIntl } from 'react-intl';

export const privacyList = [
  {
    id: 1,
    name: <FormattedMessage id='moons_only' />,
    key: 'moons_only',
    icon: <DarkModeIcon style={{ color: '#fea65a', fontSize: '14px' }} />,
  },
  {
    id: 2,
    name: <FormattedMessage id='constellation' />,
    key: 'constellation',
    icon: <PeopleAltIcon style={{ color: '#fea65a', fontSize: '14px' }} />,
  },
  {
    id: 3,
    name: <FormattedMessage id='public_view' />,
    key: 'public_view',
    icon: <PublicIcon style={{ color: '#fea65a', fontSize: '14px' }} />,
  },
];

const CreateSparks = ({ user, sparkCategories }) => {
  const intl = useIntl();
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [text, setText] = useState('');
  const [privacy, setPrivacy] = useState(privacyList[0]);
  const [openModal, setOpenModal] = useState(false);
  const [sparkCategory, setSparkCategory] = useState(sparkCategories[0] || {});
  const [createSpark, { loading: creatingSpark, error: createError }] =
    useCreateSparkMutation({
      refetchQueries: [
        {
          query: SparksDocument,
          variables: { first: 10 },
        },
      ],
      onCompleted: () => {
        setText('');
      },
    });

  const saveImage = (croppedImage) => {
    setImage({ ...image, url: croppedImage });
    setOpenModal(false);
  };

  const submitQuestion = async () => {
    try {
      await createSpark({
        variables: {
          description: text,
          sparkCategoryId: sparkCategory.id,
          visibility: privacy.key,
          location: '',
        },
      });
      showUserNotification(
        intl.formatMessage({ id: 'spark_create' }),
        'success'
      );
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(({ message }) => {
          showUserNotification(message, 'error');
        });
      }
      if (error.networkError) {
        showUserNotification(
          'Network error: ' + error.networkError.message,
          'error'
        );
      }
      if (!error.graphQLErrors && !error.networkError) {
        showUserNotification(
          'An unexpected error occurred: ' + error.message,
          'error'
        );
      }
    }
  };

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        setImage({ file, url: reader.result });
      });
      setOpenModal(true);
    }
  };

  const selectTheVideo = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        setVideo({ file, url: reader.result });
      });
    }
  };

  const selectTheAudio = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        setAudio({ file, url: reader.result });
      });
    }
  };

  return (
    <div className={styles['container']}>
      <Header
        sparkCategories={sparkCategories}
        setSparkCategory={setSparkCategory}
        sparkCategory={sparkCategory}
      />
      <User user={user} privacy={privacy} setPrivacy={setPrivacy} />
      {image && <SelectedImage image={image} setImage={setImage} />}
      {video && <SelectedVideo video={video} setVideo={setVideo} />}
      {audio && <SelectedAudio audio={audio} setAudio={setAudio} />}
      <TextArea setText={setText} text={text} />
      <Footer
        image={image}
        audio={audio}
        video={video}
        text={text}
        submitQuestion={submitQuestion}
        selectTheImage={selectTheImage}
        selectTheVideo={selectTheVideo}
        selectTheAudio={selectTheAudio}
      />
      {openModal && (
        <CropperModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          image={image}
          onSave={saveImage}
          aspectRatio={4 / 3}
        />
      )}
    </div>
  );
};

export default CreateSparks;
