import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '../../../../img/DeleteIcon.svg';
import CropIcon from '@mui/icons-material/Crop';
import { Select, MenuItem, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FamilySilhouette from '../../../../img/FamilySilhouette.svg';
import OnlyMe from '../../../../img/OnlyMe.svg';
import Everyone from '../../../../img/Everyone.svg';
import Pointer1 from '../../../../img/Pointer1.svg';
import styles from './AllImages.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPhotoForCropping,
  saveBackgroundPhoto,
  setCurrentGravePhotoIndex,
  saveListOfPhotosFromCropper,
} from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { request } from '../../../../service/request';
import DeleteModal from '../GravePictureGallery/DeleteModal';

const useStyle = makeStyles(() => ({
  rootButtonLink: {
    width: '100%',
    backgroundColor: '#36434B',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    color: '#fff',
    padding: '13px 10px',
    margin: '12px 0px',
    '&:hover': {
      backgroundColor: '#36434B',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.361)',
    },
  },
  selectItem: {
    background: '#f2f5f8',
    padding: 0,
  },
}));

const AllImages = ({
  photo,
  handleOpenSecond,
  handleOpenCropper,
  handelRemoveImage,
  isSingleImage,
  isUploading,
  isUploaded,
  category,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleChangeVale = (field, value) => {
    const updateCropImage = photoForCropping.map((image) => {
      if (image.id === photo.id) {
        return { ...image, [field]: value };
      }
      return image;
    });
    dispatch(addPhotoForCropping(updateCropImage));
  };

  const handelSetAge = () => {
    handleOpenSecond(photo);
  };

  const handelCheckBox = () => {
    if (photo.category === 'cover') {
      handleChangeVale('category', '');
    } else {
      handleChangeVale('category', 'cover');
    }
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const deleteCoverPhoto = async () => {
    try {
      await request(`/grave_images/${photo.id}`, null, 'DELETE');
      const newArray = photos.filter((image) => image.id !== photo.id);
      const newPhotoForCropping = photoForCropping.filter(
        (image) => image.id !== photo.id
      );
      if (photos.length - 1 === 0) {
        dispatch(saveBackgroundPhoto(''));
      } else if (photos.length - 1 !== 0) {
        dispatch(setCurrentGravePhotoIndex(0));
      }
      dispatch(addPhotoForCropping(newPhotoForCropping));
      dispatch(saveListOfPhotosFromCropper(newArray));
      showUserNotification('Bildet er slettet', 'success');
    } catch (err) {
      // document.location.reload();
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          deleteCoverPhoto={deleteCoverPhoto}
          closeModal={handleCloseDeleteModal}
        />
      )}
      <div className={styles['images-container']}>
        <div
          className={
            isSingleImage ? styles['grave-image-single'] : styles['grave-image']
          }
          loading='lazy'
          style={{
            backgroundImage: `url(${photo.file_url})`,
          }}
        >
          {!isUploading ? (
            <div className={styles['image-actions']}>
              {!isUploaded ? (
                <React.Fragment>
                  <button onClick={() => handleOpenCropper(photo)}>
                    <CropIcon style={{ fontSize: '14px', color: '#404D56' }} />
                  </button>
                  <button onClick={() => handelRemoveImage(photo.id)}>
                    <CloseIcon style={{ fontSize: '16px', color: '#404D56' }} />
                  </button>
                </React.Fragment>
              ) : (
                <button onClick={() => handleOpenDeleteModal()}>
                  <img src={DeleteIcon} alt='Delete' />
                </button>
              )}
            </div>
          ) : (
            <div
              className={
                isSingleImage
                  ? styles['image-loader-single']
                  : styles['image-loader']
              }
            >
              Sender....
            </div>
          )}
        </div>
        {!isUploaded && !isUploading && (
          <div className={styles['cropper-modal_content-inputs-block']}>
            <Button
              variant='contained'
              className={classes.rootButtonLink}
              onClick={handelSetAge}
            >
              <span>Hvis det er en person i bildet kan du legge til alder</span>
              <img loading='lazy' src={Pointer1} alt='pointer' />
            </Button>
            <div className={styles['description-container']}>
              <div className={styles['label']}>Beskrivelse</div>
              <div className={styles['description-wrapper']}>
                <input
                  placeholder='Skriv beskrivelse'
                  className={styles['description-input']}
                  value={photo?.comment ? photo.comment : ''}
                  onChange={(e) => {
                    handleChangeVale('comment', e.target.value);
                  }}
                />
                <Select
                  className={classes.selectItem}
                  value={photo?.privacy ? photo.privacy : 'relatives'}
                  onChange={(e) => handleChangeVale('privacy', e.target.value)}
                  IconComponent={() => <ExpandMoreOutlinedIcon />}
                >
                  <MenuItem value='default' disabled={true}>
                    Del med:
                  </MenuItem>
                  <MenuItem value='public'>
                    <img
                      loading='lazy'
                      src={Everyone}
                      alt='Family Silhouette'
                      className={styles['memories-header_right-icon']}
                    />
                    Alle
                  </MenuItem>
                  <MenuItem value='relatives'>
                    <img
                      loading='lazy'
                      src={FamilySilhouette}
                      alt='Family Silhouette'
                      className={styles['memories-header_right-icon']}
                    />
                    Familien
                  </MenuItem>
                  <MenuItem value='private'>
                    <img
                      loading='lazy'
                      src={OnlyMe}
                      alt='Family Silhouette'
                      className={styles['memories-header_right-icon']}
                      style={{ marginLeft: '3px' }}
                    />
                    Privat
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className={styles['address-container']}>
              <div className={styles['label']}>Adresse</div>
              <input
                type='text'
                value={photo?.address ? photo.address : ''}
                onChange={(e) => handleChangeVale('address', e.target.value)}
                className={styles['address-input']}
                placeholder='Legg til adresse'
              />
            </div>
            {isSingleImage && category === 'display' && (
              <div className={styles['set-cover-image']}>
                <input
                  type='checkbox'
                  checked={photo.category === 'cover'}
                  onChange={handelCheckBox}
                />
                <label>Angi som forsidebilde</label>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AllImages;
