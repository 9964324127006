import React, { useState, useEffect } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styles from './UploadUserAvatar.module.css';
import { useIntl } from 'react-intl';
import { request } from '../../../service/request';
import { Avatar } from '@mui/material';
import { makeFileFromURL } from '../../../service/makeFileFromBlob';
import { useDispatch } from 'react-redux';
import { setInformationAboutUser } from '../../../redux/actions';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import ProcessModal from '../../../containers/Payment/atoms/ProcessModal';
import CropperModal from './CropperModal/CropperModal';

const UploadUserAvatar = ({ user }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [image, setImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage({ file, url: reader.result });
        setOpenModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = async (croppedImage) => {
    setOpenModal(false);
    setOpenProcessModal(true);
    const formData = new FormData();
    const newImage = await makeFileFromURL(croppedImage, image.file.name);
    formData.append('user[avatar]', newImage, image.file?.name);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      const response = await request(
        `/users/${user.slug}`,
        formData,
        'put',
        headers
      );
      dispatch(setInformationAboutUser(response.data));
      setOpenProcessModal(false);
      showUserNotification(
        intl.formatMessage({ id: 'profile_image_changed' }),
        'success'
      );
    } catch (error) {
      setOpenProcessModal(false);
      showUserNotification(error.response.data.error || error.message, 'error');
    }
  };

  return (
    <label className={styles['user-avatar']} htmlFor='file-input'>
      <Avatar src={user?.avatar} className={styles['avatar']} />
      <div className={styles['container']}>
        <CameraAltIcon className={styles['camera-icon']} />
        <input
          id='file-input'
          type='file'
          accept='image/jpeg, image/jpg, image/png'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {openModal && (
          <CropperModal
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
            image={image}
            aspectRatio={1 / 1}
            onSave={handleCrop}
          />

          //   <CustomModal
          //     isOpen={openModal}
          //     closeModal={() => setOpenModal(false)}
          //     title={<FormattedMessage id='copper' />}
          //     children={
          //       <React.Fragment>
          //         <Cropper
          //           ref={cropperRef}
          //           className={styles['cropper']}
          //           src={image?.url}
          //           viewMode={1}
          //           guides={true}
          //           zoomOnWheel={false}
          //           minCropBoxHeight={10}
          //           minCropBoxWidth={10}
          //           background={false}
          //           responsive={true}
          //           autoCropArea={1}
          //           aspectRatio={1 / 1}
          //           checkOrientation={false}
          //         />
          //         <div className={styles['btn']}>
          //           <PrimaryButton
          //             onClick={handleCrop}
          //             children={<FormattedMessage id='auth_save' />}
          //           />
          //         </div>
          //       </React.Fragment>
          //     }
          //   />
        )}
        {openProcessModal && (
          <ProcessModal
            open={openProcessModal}
            messageKey='waiting_profile_upload'
          />
        )}
      </div>
    </label>
  );
};

export default UploadUserAvatar;
