import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import DeceasedTopHeader from '../../containers/NewDeceased/DeceasedTopHeader';
import BackIcon from '../../img/BackIcon.svg';
import spinner from '../../service/Loading_animation.gif';
import PersonalityWrapper from '../../containers/NewDeceased/atoms/PersonalityWrapper/PersonalityWrapper';
import styles from './NewDeceased.module.css';
import {
  addComment,
  addProfilePhotoList,
  goToChosenGrave,
  setGraveUsersList,
  saveListOfPhotosFromCropper,
  spinnerStart,
  spinnerFinish,
  setSubscription,
  addCommentOnToggle,
  setCurrentGravePhotoIndex,
  setInformationAboutUser,
} from '../../redux/actions';
import { request } from '../../service/request';
import DeceasedMenu from '../../containers/NewDeceased/atoms/DeceasedMenu/DeceasedMenu';
import DeceasedImageContainer from '../../containers/NewDeceased/DeceasedImageContainer';
import ClaimMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimMessage';
import BackButton from '../../components/BackButton/BackButton';
import isEmpty from 'lodash.isempty';
import GravePendingRelations from '../../containers/NewDeceased/atoms/GravePendingRelations/GravePendingRelations';
import { CONSTANTS } from '../../service/constants';
import Loader from '../../components/Loader/Loader';
import AuthModal from '../../components/Modals/AuthModal';
import { useNavigate } from 'react-router-dom';

const NewDeceasedPage = (props) => {
  const navigate = useNavigate();
  const tabValue = useSelector(
    (state) => state.photoReducer.currentDeceasedTabIndex
  );
  const tabRef = useRef();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const isLoading = useSelector((state) => state.spinnerLoad.isLoading);
  const [user, setUser] = useState({});
  const [currentGrave, setCurrentGrave] = useState({});
  const grave = useSelector((state) => state.grave.grave);
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;

  const dispatch = useDispatch();

  let { slug } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (tabRef.current) {
        const extraHeight = 50;
        const rect = tabRef.current.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top + extraHeight,
          behavior: 'smooth',
        });
      }
    }, 0.1);
    return () => clearTimeout(timeoutId);
  }, [tabValue]);

  const fetchGrave = async () => {
    try {
      if (slug) {
        dispatch(spinnerStart());
        dispatch(goToChosenGrave({}));
        dispatch(setCurrentGravePhotoIndex(0));
        dispatch(addProfilePhotoList([]));
        dispatch(saveListOfPhotosFromCropper([]));
        const response = await request(`/graves/${slug}`, null, 'get');
        dispatch(goToChosenGrave(response.data));
        dispatch(addCommentOnToggle({}));
        if (
          response.data.grave_images &&
          response.data.grave_images.length > 0
        ) {
          const profilePhotos = response.data.grave_images
            .filter((image) => image.category === 'display')
            .map((el) => ({
              ...el,
              image_comments: el.image_comments?.map((com) => ({
                ...com,
                description: com.comment,
              })),
            }));
          if (response.data.memories && response.data.memories.length > 0) {
            dispatch(addComment(response.data.memories));
          }
          dispatch(addProfilePhotoList(profilePhotos));
          dispatch(saveListOfPhotosFromCropper(response.data.grave_images));
        }
        if (response.data.grave_users && response.data.grave_users.length > 0) {
          dispatch(setGraveUsersList(response.data.grave_users));
        } else {
          dispatch(setGraveUsersList([]));
        }
        setCurrentGrave(response.data);
        setTimeout(() => {
          dispatch(spinnerFinish());
        }, 550);
      }
    } catch (err) {
      console.log(err);
      err.message === 'Request failed with status code 500' &&
        console.log('document.location.reload()');
    }
  };

  useEffect(() => {
    fetchGrave();
  }, [localStorage.getItem('token'), slug]);

  const fetchUserData = () => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}`).then((res) => {
          dispatch(setInformationAboutUser(res.data));
          dispatch(setSubscription(res.data.user.subscription));
          setUser(res.data.user);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [slug]);

  if (!isAuth) {
    return <AuthModal isOpen={true} handleClose={() => navigate('/')} />;
  }

  if (isLoading || (userSlug && isEmpty(user)) || isEmpty(currentGrave))
    return <Loader />;
  return (
    <div className={styles['deceased-page-container']}>
      <div className={styles['deceased-page']}>
        <BackButton fallBackUrl={'/graves'} />
        {isAuth && grave.can_claim && !grave.current_user_in_claim_queue && (
          <ClaimMessage grave={grave} isAuth={isAuth} />
        )}
        <DeceasedTopHeader
          isAuth={isAuth}
          user={user}
          grave={grave}
          isClaimed={isClaimed}
        />
        {!isClaimed && (
          <GravePendingRelations grave={grave} isAuth={isAuth} user={user} />
        )}
        <DeceasedImageContainer isAuth={isAuth} grave={grave} />
        {grave.can_add_virtue && (
          <div className={styles['deceased-virtue']} ref={tabRef}>
            <PersonalityWrapper grave={grave} isAuth={isAuth} />
          </div>
        )}
        <div className={styles['menu-tabs-deceased']}>
          <DeceasedMenu
            isAuth={isAuth}
            grave={grave}
            user={user}
            isClaimed={isClaimed}
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default NewDeceasedPage;
