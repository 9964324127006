import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(( ) => ({
  large: {
    width:  (14),
    height:  (14),
    borderRadius: 10,
    marginBottom: 12,
  },
}));

const UserAvatar = ({ src }) => {
  const classes = useStyles();

  return <Avatar src={src} className={classes.large} />;
};

export default UserAvatar;
