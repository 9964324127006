import React, { useState } from 'react';
import Header from './Header';
import EditDescription from './EditDescription';
import styles from './SparksList.module.css';
import { privacyList } from '../CreateSparks/CreateSparks.jsx';
import {
  useUpdateSparkMutation,
  SparksDocument,
} from '../../../../../../graphql/generated/sparkHooks.ts';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification.js';
import { useIntl } from 'react-intl';

const SparkPost = ({ spark, sparkCategories }) => {
  const intl = useIntl();
  const [editSpark, setEditSpark] = useState(false);
  const [description, setDescription] = useState('');
  const [sparkCategory, setSparkCategory] = useState({});
  const [privacy, setPrivacy] = useState('');
  const [updateSpark] = useUpdateSparkMutation({
    refetchQueries: [
      {
        query: SparksDocument,
        variables: { first: 10 },
      },
    ],
    onCompleted: () => {
      setEditSpark(false);
      setDescription('');
      setSparkCategory({});
      setPrivacy('');
    },
  });

  const onSave = async () => {
    try {
      await updateSpark({
        variables: {
          id: spark.id,
          description: description,
          sparkCategoryId: sparkCategory.id,
          visibility: privacy.key,
        },
      });
      showUserNotification(
        intl.formatMessage({ id: 'spark_update' }),
        'success'
      );
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(({ message }) => {
          showUserNotification(message, 'error');
        });
      }
      if (error.networkError) {
        showUserNotification(
          'Network error: ' + error.networkError.message,
          'error'
        );
      }
      if (!error.graphQLErrors && !error.networkError) {
        showUserNotification(
          'An unexpected error occurred: ' + error.message,
          'error'
        );
      }
    }
  };

  const onClickEdit = (spark) => {
    setEditSpark(true);
    setDescription(spark.description);
    const findCategory = sparkCategories.find(
      (category) => category.id === spark.sparkCategoryId
    );
    const findPrivacy = privacyList.find(
      (privacy) => privacy.key === spark.visibility
    );
    if (findCategory) {
      setSparkCategory(findCategory);
    }
    if (findPrivacy) {
      setPrivacy(findPrivacy);
    }
  };

  const onCloseEdit = () => {
    setEditSpark(false);
    setDescription('');
  };
  return (
    <div className={styles['spark-container']} key={spark.id}>
      <Header
        spark={spark}
        onClickEdit={onClickEdit}
        editSpark={editSpark}
        sparkCategories={sparkCategories}
        privacy={privacy}
        sparkCategory={sparkCategory}
        setPrivacy={setPrivacy}
        setSparkCategory={setSparkCategory}
      />
      {editSpark ? (
        <EditDescription
          description={description}
          setDescription={setDescription}
          onCloseEdit={onCloseEdit}
          onSave={onSave}
        />
      ) : (
        <div className={styles['description']}>{spark.description}</div>
      )}
    </div>
  );
};

export default SparkPost;
