import React from 'react';
import './MusicPlayer.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Play from '../../../img/TrianglePlay.svg';
import Pause from '../../../img/pause.png';
import { Button } from '@mui/material';
import Next from '../../../img/BigNext.svg';
import Prev from '../../../img/BigPrev.svg';
import { createRef } from 'react';

const MusicPlayer = (props) => {

    const player = createRef();
    const [playing, setPlaying] = React.useState(false);
    const source = props.file;

    const play = () => {
        player.current.audio.current.play();
        setPlaying(true);
    }

    const pause = () => {
        player.current.audio.current.pause();
        setPlaying(false);
    }

    const nextSec = () => {
        player.current.audio.current.currentTime += 5;
    }

    const prevSec = () => {
        if(player.current.audio.current.currentTime > 5) {
            player.current.audio.current.currentTime -= 5;
        }
    }

    const handlePlayPause = () => {
        return playing ? pause() : play(); 
    }

    return(
        <div className="music-player">
          <AudioPlayer autoPlay={false} ref={player} src={source} layout='horizontal-reverse' className='music-player_player' customAdditionalControls={[]}/>
          <div className="music-player-buttons">
            <Button onClick={prevSec} className='music-player_button-secs-prev'><img loading='lazy' src={Prev} alt=''/></Button>
            <Button className='music-player_button' onClick={handlePlayPause}><img loading='lazy' src={playing ? Pause : Play} alt='' className='music-player_button-image'/></Button>
            <Button onClick={nextSec} className='music-player_button-secs-next'><img loading='lazy' src={Next} alt=''/></Button>
          </div> 
        </div>
    )
}

export default MusicPlayer;