import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState, useEffect } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import styles from './GraveAvatar.module.css';
import GraveAvatarModal from './GraveAvatarModal/GraveAvatarModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles(( ) => ({
  large: {
    width: 100,
    height: 100,
    borderRadius: '10px',
    border: '1px solid #bdbdbd',
  },
}));

const GraveAvatar = ({ avatar, user, grave }) => {
  const classes = useStyles();
  const [image, setImage] = useState();
  const [openCropper, setOpenCropper] = useState();
  const inputFile = useRef();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (grave.grave_users && grave.grave_users.length > 0 && user) {
      const graveUser = grave.grave_users.find((u) => u.user_id === user.id);
      if (!isEmpty(graveUser) && (graveUser.admin || graveUser.grave_owner)) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, [grave]);

  const handleProfilePhotoSelection = () => {
    if (isAdmin) {
      inputFile.current.click();
    }
  };

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        if (file.size / (1024 * 1024) <= 20) {
          setImage({ file, url: reader.result });
          setOpenCropper(true);
        } else {
          showUserNotification(
            'Velg en fil som ikke er større enn 20MB',
            'warning'
          );
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className={styles['grave-avatar-container']}>
        <Avatar
          loading='lazy'
          src={avatar ? avatar : ''}
          className={classes.large}
          onClick={() => handleProfilePhotoSelection()}
        />
        {isAdmin && (
          <div
            className={styles['camera-icon']}
            onClick={() => handleProfilePhotoSelection()}
          >
            <input
              style={{ display: 'none' }}
              ref={inputFile}
              type='file'
              accept='image/jpeg, image/jpg, image/png'
              className='test'
              onChange={selectTheImage}
            />
            <CameraAltIcon style={{ color: '#36434b' }} />
          </div>
        )}
      </div>
      {openCropper && (
        <GraveAvatarModal
          image={image}
          handleCloseCropper={() => setOpenCropper(false)}
          openCropper={openCropper}
        />
      )}
    </React.Fragment>
  );
};

export default GraveAvatar;
