import React from 'react';
import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '19px 16px',
    maxWidth: '309px',
  },
  confirmationPaper: {
    maxWidth: 'unset !important',
  },
  root: {
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'unset !important',
    },
  },
}));

const PopoverModal = ({
  grave,
  open,
  handlePopoverClose,
  openPhysicalPopover,
}) => {
  const classes = useStyle();

  return (
    <Popover
      id='mouse-over-popover'
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={openPhysicalPopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div className='mouse-over-popover_physical'>
        <span className='mouse-over-popover_physical-header'>
          Graven er fortsatt å finne på en gravlund:
          <br />
          <br />
        </span>
        <span className='mouse-over-popover_physical-main'>
          <span>{grave?.plot_number}:</span> Graven sin lokasjon på gravlunden
          er.
        </span>
        {grave?.plot_number?.split('.')[0] ? (
          <span className='mouse-over-popover_physical-main'>
            <span>
              {grave?.plot_number?.split('.')[0]
                ? grave?.plot_number?.split('.')[0]
                : ' '}
              :
            </span>{' '}
            Indikerer hvilken gravlund
          </span>
        ) : (
          grave?.plot_number?.split(':')[0] && (
            <span className='mouse-over-popover_physical-main'>
              <span>{grave?.plot_number?.split(':')[0]}:</span> Navnet på
              gravlunden
            </span>
          )
        )}
        {grave?.plot_number?.split('.')[1] ? (
          <span className='mouse-over-popover_physical-main'>
            <span>{grave?.plot_number?.split('.')[1]}:</span> Området inne på
            gravlunden
          </span>
        ) : (
          grave?.plot_number?.split(':')[1] && (
            <span className='mouse-over-popover_physical-main'>
              <span>{grave?.plot_number?.split(':')[1]}:</span> Området inne på
              gravlunden
            </span>
          )
        )}
        {grave?.plot_number?.split('.')[2] ? (
          <span className='mouse-over-popover_physical-main'>
            <span>{grave?.plot_number?.split('.')[2]}:</span> Rekken du finner
            graven
          </span>
        ) : (
          grave?.plot_number?.split(':')[2] && (
            <span className='mouse-over-popover_physical-main'>
              <span>{grave?.plot_number?.split(':')[2]}:</span> Rekken du finner
              graven
            </span>
          )
        )}
        {grave?.plot_number?.split('.')[3] ? (
          <span className='mouse-over-popover_physical-main'>
            <span>{grave?.plot_number?.split('.')[3]}:</span> Raden i rekken
            graven befinner seg
          </span>
        ) : (
          grave?.plot_number?.split(':')[3] && (
            <span className='mouse-over-popover_physical-main'>
              <span>{grave?.plot_number?.split(':')[3]}:</span> Raden i rekken
              graven befinner seg
            </span>
          )
        )}
      </div>
    </Popover>
  );
};

export default PopoverModal;
