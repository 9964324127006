import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(( ) => ({
  large: {
    width: 120,
    height: 120,
    borderRadius: '10px',
    border: '1px solid #bdbdbd',
  },
}));

const GraveImage = ({ avatar }) => {
  const classes = useStyles();
  return (
    <div className='avatar'>
      <Avatar
        loading='lazy'
        src={avatar ? avatar : ''}
        className={classes.large}
      />
    </div>
  );
};

export default GraveImage;
